import { Box } from "@interstate/components/Box";
import { TextArea } from "@interstate/components/TextArea";
import { PriceInput } from "@interstate/components/PriceInput";
import { useCallback, useEffect, useState } from "react";
import { InterstateOnChangeEvent } from "@interstate/components/InterstateEvents";
import { CompleteAcquisitionInformationProps } from "../interfaces/acquisition-information-props";
import {
  CustomProcess,
  LeadMetadata,
  UserPermissions,
  getLeadMetaData,
  getProcesses,
  getUserPermissions,
  setMappedResults
} from "@vinsolutions/data-access/lead/crm.lead.bff";
import {
  ComboBox,
  ComboBoxEventValue,
  ComboBoxOptions
} from "@interstate/components/ComboBox";

function getValue(
  options: ComboBoxOptions,
  selectedValue: string | number
): ComboBoxOptions | undefined {
  const selectedOption = options.find(
    s => s.value === selectedValue.toString()
  );

  return selectedOption ? [selectedOption] : undefined;
}

export function CompleteAcquisitionInformation({
  dealerId,
  jwt,
  userId,
  vehicleInformation
}: CompleteAcquisitionInformationProps) {
  const [statuses, setStatuses] = useState<ComboBoxOptions>([]);
  const [processes, setProcesses] = useState<ComboBoxOptions>([]);
  const [userPermissions, setUserPermissions] = useState<UserPermissions>();

  // TODO: Not sure where these come from yet
  const [status, setStatus] = useState<number>(1);
  const [process, setProcess] = useState<number>(1);
  const [offerAmount, setOfferAmount] = useState<string>("");
  const [notes, setNotes] = useState<string>("");

  const loadDropdownData = useCallback(async () => {
    if (jwt && dealerId > 0) {
      const [fetchedMetadata, fetchedProcesses] = await Promise.all([
        getLeadMetaData(dealerId, jwt, true) as Promise<
          LeadMetadata | null | undefined
        >,
        getProcesses(dealerId, jwt, true) as Promise<
          CustomProcess[] | null | undefined
        >
      ]);

      setMappedResults(setProcesses, fetchedProcesses);
      setMappedResults(setStatuses, fetchedMetadata?.leadStatuses);
    }
  }, [dealerId, jwt]);

  const loadUserPermissions = useCallback(async () => {
    const result =
      jwt && dealerId > 0 && userId > 0
        ? await getUserPermissions(dealerId, userId, jwt, true)
        : null;

    if (result) {
      setUserPermissions(result);
    }
  }, [dealerId, jwt, userId, setUserPermissions]);

  useEffect(() => {
    loadUserPermissions();

    loadDropdownData();
  }, [loadDropdownData, loadUserPermissions]);

  useEffect(() => {
    setOfferAmount(vehicleInformation.appraisalValue || "");
  }, [setOfferAmount, vehicleInformation]);

  function onChange(
    event: InterstateOnChangeEvent<ComboBoxEventValue>,
    options: ComboBoxOptions,
    setter: (value: number) => void
  ) {
    if (
      !event.isValid ||
      !event.target.value ||
      !Array.isArray(event.target.value) ||
      event.target.value.length === 0 ||
      !event.target.value[0].value
    ) {
      return;
    }

    const id = options.find(
      obj => obj.value === event.target.value[0].value
    )?.value;

    const value = id ? +id : undefined;

    if (value) {
      setter(value);
    }
  }

  return (
    <Box
      columnGap={"1em"}
      display={"grid"}
      gridTemplateColumns={"1fr"}
      paddingTop={"1em"}
      rowGap={"0.75em"}
    >
      <ComboBox
        label="Current status / outcome of the Acquisition"
        name="status-input"
        options={statuses}
        size="small"
        value={getValue(statuses, status)}
        onChange={event => onChange(event, statuses, setStatus)}
      />

      {userPermissions?.canViewProcesses && (
        <ComboBox
          label="Followup Process"
          name="process-input"
          options={processes}
          size="small"
          value={getValue(processes, process)}
          onChange={event => onChange(event, processes, setProcess)}
        />
      )}

      <PriceInput
        allowNegative={false}
        id="offer-input"
        label="Final Offer Amount"
        name="offer-input"
        size="small"
        value={offerAmount}
        onChange={function (event: InterstateOnChangeEvent<string>): void {
          setOfferAmount(event.target.value);
        }}
      />

      <TextArea
        label="Notes (optional)"
        name="notes-input"
        rows={2}
        size="small"
        value={notes}
        onChange={function (event: InterstateOnChangeEvent<string>): void {
          setNotes(event.target.value);
        }}
      />
    </Box>
  );
}

export default CompleteAcquisitionInformation;
