import { useCallback, useEffect, useState } from "react";
import { getLeadCount } from "@vinsolutions/data-access/lead/crm.lead.bff";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { AcquisitionLeadTabProps } from "../interfaces/acquisition-lead-tab-props";
import { createGlobalStyle } from "styled-components";

const FixPortalStyle = createGlobalStyle`
  #acquisition-lead-tab-container {
    display: inline-block;
  }
`;

declare global {
  interface Window {
    HideAcquisitionTab: () => void;
    ShowAcquisitionTab: () => void;
    getRightFrameContent: () => Window;
  }
}

export function AcquisitionLeadTab({ customerId }: AcquisitionLeadTabProps) {
  const [leadCount, setLeadCount] = useState<number>();

  const oidcUser = useReactOidc().oidcUser;

  if (!customerId || customerId <= 0) {
    throw new Error("customerId parameter is required");
  }

  const loadLeadCount = useCallback(async () => {
    const result =
      oidcUser.access_token && customerId > 0
        ? await getLeadCount(customerId, oidcUser.access_token, true)
        : null;

    if (result !== undefined && result !== null) {
      setLeadCount(result);
    }
  }, [customerId, oidcUser, setLeadCount]);

  useEffect(() => {
    loadLeadCount();
  }, [customerId, loadLeadCount]);

  useEffect(() => {
    if (leadCount && leadCount > 0) {
      window.getRightFrameContent().ShowAcquisitionTab();
    } else {
      window.getRightFrameContent().HideAcquisitionTab();
    }
  }, [leadCount]);

  const tabContent =
    leadCount && leadCount > 0 ? `Acquisition (${leadCount})` : "Acquisition";

  return (
    <span className="rtsOut">
      <FixPortalStyle />
      <span className="rtsIn">
        <span className="rtsTxt">{tabContent}</span>
      </span>
    </span>
  );
}
