import { Box } from "@interstate/components/Box";
import styled from "styled-components";
import { Button } from "@interstate/components/Button";
import { Tooltip } from "@interstate/components/Tooltip";

const StyledColumn = styled.div`
  flex: 1 1 0;
  text-align: center;

  button.MuiButton-root div.MuiBox-root svg[class*="interstate"] {
    font-size: revert-layer !important;
  }
`;

const StyledTooltip = styled(Tooltip)`
  && {
    width: revert;
  }
`;

export interface CtaProps {
  disabled?: boolean;
  icon: JSX.Element;
  title: string;
  tooltip?: string;
  onClick: () => void;
}

export const disabledCtaColor = "lightgrey";
export const defaultCtaColor = "black";
export const ctaIconFontSize = "22px";

export function Cta({ disabled, icon, title, tooltip, onClick }: CtaProps) {
  const ctaContent = (
    <Button buttonStyle="tertiary" disabled={disabled} onClick={onClick}>
      <Box
        color={disabled ? disabledCtaColor : defaultCtaColor}
        fontSize={"1rem"}
        fontWeight={"normal"}
      >
        {icon}
        <div>{title}</div>
      </Box>
    </Button>
  );
  return (
    <StyledColumn>
      {tooltip ? (
        <StyledTooltip position={"top"} size="small" toolTipContent={tooltip}>
          {ctaContent}
        </StyledTooltip>
      ) : (
        ctaContent
      )}
    </StyledColumn>
  );
}
export default Cta;
