import { useEffect } from "react";
import { AcquisitionLeadPanelPortalProps } from "../interfaces/acquisition-lead-panel-portal-props";
import { usePortalsContext } from "@vinsolutions/utility/portals";
import { useVinconnectFlagEnabled } from "@vinsolutions/core/third-party/launch-darkly";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AcquisitionLeadPanel } from "@vinsolutions/page/acquisition-lead-panel";

export function AcquisitionLeadPanelPortal({
  customerId,
  selectedLeadId,
  portalId,
  portalEnabledCallback
}: AcquisitionLeadPanelPortalProps) {
  const { setPortalVisibility } = usePortalsContext();

  const portalFlagEnabled = useVinconnectFlagEnabled(
    "nx.vinconnect.vehicle-acquisition.add-lead.enable"
  );

  useEffect(() => {
    setPortalVisibility(portalId, portalFlagEnabled);
    if (portalEnabledCallback) {
      portalEnabledCallback(portalFlagEnabled);
    }
  }, [portalFlagEnabled, portalId, portalEnabledCallback, setPortalVisibility]);

  return (
    <AcquisitionLeadPanel
      customerId={customerId}
      selectedLeadId={selectedLeadId}
    />
  );
}
