import { Button } from "@interstate/components/Button";
import { Box } from "@interstate/components/Box";
import { EditVehicleVehicleCtasProps } from "../interfaces/vehicle-ctas-props";
import {
  VehicleInformation,
  deleteVehicle,
  updateVehicle
} from "@vinsolutions/data-access/lead/crm.lead.bff";
import { useCallback } from "react";
import { TagManager } from "@vinsolutions/core-third-party-gtm";

export function EditVehicleVehicleCtas({
  leadId,
  jwt,
  vehicleInformation,
  setVehicleInformation,
  onClose
}: EditVehicleVehicleCtasProps) {
  const callDeleteVehicle = useCallback(async () => {
    if (jwt && leadId && leadId > 0) {
      // TODO: Update GTM event once requirements are known (or replace with PixAll?)
      TagManager.event({
        event: "userEvent",
        eventElement: "EditVehicle",
        eventAction: `Delete vehicle button clicked`,
        eventResult: `Deleted vehicle`,
        eventLocation: "EditVehicle"
      });

      const result = await deleteVehicle(leadId, jwt, true);

      if (result) {
        setVehicleInformation({} as VehicleInformation);
      } else {
        // TODO: Show error message
      }

      if (onClose) {
        onClose();
      }
    }
  }, [leadId, jwt, setVehicleInformation]);

  const callUpdateVehicle = useCallback(async () => {
    if (
      jwt &&
      leadId &&
      leadId > 0 &&
      vehicleInformation &&
      vehicleInformation.make &&
      vehicleInformation.model &&
      vehicleInformation.trim
    ) {
      // TODO: Update GTM event once requirements are known (or replace with PixAll?)
      TagManager.event({
        event: "userEvent",
        eventElement: "EditVehicle",
        eventAction: `Update vehicle button clicked`,
        eventResult: `Updated vehicle`,
        eventLocation: "EditVehicle"
      });
      const result = await updateVehicle(leadId, jwt, vehicleInformation, true);

      if (!result) {
        // TODO: Show error message
      }

      if (onClose) {
        onClose();
      }
    }
  }, [leadId, jwt, vehicleInformation]);

  return (
    <Box
      columnGap="1.5em"
      display={"flex"}
      justifyContent={"flex-end"}
      paddingTop={"1em"}
    >
      {onClose && (
        <Button
          buttonStyle="tertiary"
          data-testid="button-cancel"
          id="cancel-button"
          size="medium"
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
        >
          Cancel
        </Button>
      )}

      <Box>
        <Button
          buttonStyle="tertiary"
          data-testid="button-delete"
          id="delete-button"
          size="medium"
          onClick={() => callDeleteVehicle()}
        >
          Delete Vehicle
        </Button>
      </Box>

      <Button
        buttonStyle="primary"
        data-testid="button-update"
        id="update-button"
        size="medium"
        onClick={() => callUpdateVehicle()}
      >
        Save
      </Button>
    </Box>
  );
}

export default EditVehicleVehicleCtasProps;
