import { FunctionComponent } from "react";

export type PortalEnablementCallback = (portalEnabled: boolean) => void;

export interface PortalComponentProps {
  portalId: string;
  portalHtmlId?: string;
  data?: unknown; // unable to use generic because portal createContext requires a known/static type
  portalEnabledCallback?: PortalEnablementCallback;
}

export interface PortalMessage {
  originElement: WeakRef<HTMLElement>;
  originDocument: WeakRef<Document>;
  portalId: string;
  portalKey: string;
  portalProps?: PortalComponentProps;
}

export interface PortalEventMessage {
  originDocument: Document;
  originFrameUrl: URL;
  registerPortals: PortalIdMapping[];
  data?: unknown; // unable to use generic because portal createContext requires a known/static type
}

export interface PortalEventChannels {
  createPortals: string;
  deletePortals: string;
}

export interface PortalIdMapping {
  targetElementId?: string;
  targetElement?: HTMLElement;
  portalId: string;
  portalEnabledCallback?: PortalEnablementCallback;
}

export interface RegisteredPortal<T extends PortalComponentProps> {
  originElement: WeakRef<HTMLElement>;
  originDocument: WeakRef<Document>;
  portalId: string;
  portalKey: string;
  isHidden?: boolean;
  portalComponent: FunctionComponent<T>;
  portalComponentProps: T;
}

export interface RegisteredPortalFactory<T extends PortalComponentProps> {
  create: (portalMessage: PortalMessage) => RegisteredPortal<T> | null;
}

export interface PortalsContextValue {
  registeredPortals: Record<string, RegisteredPortal<PortalComponentProps>>;
  setPortalVisibility: (targetPortalId: string, isVisible: boolean) => void;
  deletePortal: (targetPortalId: string, targetPortalKey?: string) => void;
}

export interface PortalsContextAction {
  registerPortalMessage?: PortalMessage;
  targetPortalId?: string;
  targetPortalKey?: string;
  type: PortalContextActionTypes;
  portalFactoryRegistry: PortalFactoryRegistry;
}

export const enum PortalContextActionTypes {
  createPortals = "create",
  deletePortals = "delete",
  showPortal = "show",
  hidePortal = "hide",
  markForDeletion = "markForDeletion"
}

export type PortalFactoryRegistry = Record<
  string,
  RegisteredPortalFactory<PortalComponentProps> | null
>;

export default PortalComponentProps;
