import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRenderRoute } from "./use-render-route";
import { useVinconnectFlags } from "@vinsolutions/core/third-party/launch-darkly";
import { RouteManager } from "./route-manager";
import {
  RouteBackButton,
  routeList
} from "@vinsolutions/ccrm/top-nav/route-config";
import { NavigateFunction } from "react-router";

// TODO this should be type used by history.push params types
// but react-router-dom types are difficult to import.
type HistoryPushParams = {
  location: string;
  state?: any;
  productName?: string;
  viewName?: string;
  routeBackButton?: RouteBackButton;
};

declare global {
  interface WindowEventMap {
    "history:push": CustomEvent<HistoryPushParams>;
  }
}
export const pushCD = (
  ev: CustomEvent<HistoryPushParams>,
  routeManager: RouteManager,
  renderRoute: {
    (
      productName: string,
      viewName: string,
      queryParams: Record<string, string | number> | null | undefined,
      routeBackButton?: RouteBackButton
    ): void;
    (arg0: any, arg1: any, arg2: any, arg3: any): void;
  },
  navigate: NavigateFunction
) => {
  if (routeManager?.isActiveRoute(ev.detail.productName, ev.detail.viewName)) {
    renderRoute(
      ev.detail.productName,
      ev.detail.viewName,
      ev.detail.state,
      ev.detail.routeBackButton
    );
  } else {
    navigate(ev.detail.location, { state: ev.detail.state, replace: false });
  }
};
/**
 * @description replicate history.push from 'react-router-dom' to be used by the cardashboard pages
 * as a customEvent by the name of 'history:push'
 */
export const useHistoryPushCd = () => {
  const navigate = useNavigate();
  const vinconnectFlags = useVinconnectFlags();
  const renderRoute = useRenderRoute();

  const pushHistoryHandle = useCallback(
    (ev: CustomEvent<HistoryPushParams>) => {
      const routeManager = new RouteManager(routeList, vinconnectFlags);
      pushCD(ev, routeManager, renderRoute, navigate);
    },
    [navigate, renderRoute, vinconnectFlags, useVinconnectFlags]
  );

  useEffect(() => {
    window.addEventListener("history:push", pushHistoryHandle);
    return () => {
      window.removeEventListener("history:push", () => pushHistoryHandle);
    };
  });
};
