import { Portals } from "@vinsolutions/utility/portals";
import VinConnectPortalEventChannels from "./vinconnect-portal-event-channels";
import VinconnectPortalFactoryRegistry from "./vinconnect-portal-factory-registry";
import RegisterVinconnectReactPortals from "./vinconnect-portal-register";

declare global {
  interface Window {
    RegisterVinconnectReactPortals: typeof RegisterVinconnectReactPortals;
  }
}

const getPortalsCss = () => {
  let portalCss = "";
  document?.head
    ?.querySelectorAll<HTMLStyleElement>(
      "style:not([data-emotion]):not([data-styled])"
    )
    .forEach(style => {
      portalCss += style.innerText;
    });
  return portalCss;
};

export const VinconnectPortals = () => {
  // define the vinconnect portal registration function
  if (window && window.top && !window.top.RegisterVinconnectReactPortals) {
    window.top.RegisterVinconnectReactPortals = RegisterVinconnectReactPortals;
  }
  const portalCss = getPortalsCss();
  return (
    <Portals
      portalContainerId="vinconnect-portals"
      portalCss={portalCss}
      portalEventChannels={VinConnectPortalEventChannels}
      portalFactoryRegistry={VinconnectPortalFactoryRegistry}
    />
  );
};
