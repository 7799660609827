import { FC } from "react";
import PortalComponentProps, { RegisteredPortal } from "../interfaces/portals";
import PortalStyleProvider from "./portal-style-provider";
import { usePortalCleanup } from "../hooks/use-portal-cleanup";

type PortalComponentWrapperProps = {
  registeredPortal: RegisteredPortal<PortalComponentProps>;
  targetContainer: HTMLElement | ShadowRoot;
};

export const PortalComponentWrapper: FC<PortalComponentWrapperProps> = ({
  registeredPortal,
  targetContainer
}: PortalComponentWrapperProps) => {
  usePortalCleanup(registeredPortal);

  return (
    // Target the portal origin document so styled components styles get applied in the frame where the portal is being rendered
    <PortalStyleProvider
      cacheKey={registeredPortal.portalId}
      targetContainer={targetContainer}
    >
      <registeredPortal.portalComponent
        {...registeredPortal.portalComponentProps}
      />
    </PortalStyleProvider>
  );
};

export default PortalComponentWrapper;
