import { createPortal } from "react-dom";
import { ReactPortal, useRef } from "react";
import PortalComponentProps, { RegisteredPortal } from "../interfaces/portals";
import useShadowRoot from "../hooks/use-shadow-root";
import PortalComponentWrapper from "./portal-component-wrapper";
import usePortalTarget from "../hooks/use-portal-target";

type props = {
  registeredPortal: RegisteredPortal<PortalComponentProps>;
  /** CSS applied to created portal container elements.
   * Useful when needing to apply styles defined outside the document the portal is being rendered in */
  portalCss?: string | null;
};

const Portal = ({ portalCss, registeredPortal }: props) => {
  const portalRef = useRef<ReactPortal | null>(null);
  const target = usePortalTarget(registeredPortal);
  const shawdowRoot = useShadowRoot(target);

  if (!shawdowRoot) return null;

  /**
   * Evaluate the portal lazily:
   * - We need first render to create the Portal, so it shouldn't happen in useEffect.
   * - The registered portal's origin element cannot be switched to a different DOM target,
   * so we only need to inject the css and create the portal once.
   * @link https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
   */
  const getPortal = () => {
    if (portalRef.current === null) {
      // inject portal styles into the shadow root
      if (portalCss) {
        const portalStyle = document.createElement("style");
        portalStyle.innerText = portalCss;
        shawdowRoot.appendChild(portalStyle);
      }
      portalRef.current = createPortal(
        <PortalComponentWrapper
          registeredPortal={registeredPortal}
          targetContainer={shawdowRoot}
        />,
        shawdowRoot,
        registeredPortal.portalKey
      );
    }
    return portalRef.current;
  };
  const portal = getPortal();
  return portal;
};

export default Portal;
