import { Box } from "@interstate/components/Box";
import {
  ComboBox,
  ComboBoxEventValue,
  ComboBoxOptions
} from "@interstate/components/ComboBox";
import { EditLeadLeadInformationProps } from "../interfaces/lead-information-props";
import { useCallback, useEffect, useState } from "react";
import {
  LeadMetadata,
  User,
  UserType,
  getLeadMetaData,
  getUsers,
  setMappedResults
} from "@vinsolutions/data-access/lead/crm.lead.bff";
import { TextInput } from "@interstate/components/TextInput";
import { CheckBox, CheckBoxEventValue } from "@interstate/components/CheckBox";
import { InterstateOnChangeEvent } from "@interstate/components/InterstateEvents";

function getValue(
  options: ComboBoxOptions,
  selectedValue: string | number
): ComboBoxOptions | undefined {
  const selectedOption = options.find(
    s => s.value === selectedValue.toString()
  );

  return selectedOption ? [selectedOption] : undefined;
}

export function EditLeadLeadInformation({
  dealerId,
  jwt,
  leadInformation,
  setLeadInformation
}: EditLeadLeadInformationProps) {
  const [intents, setIntents] = useState<ComboBoxOptions>([]);
  const [types, setTypes] = useState<ComboBoxOptions>([]);
  const [sources, setSources] = useState<ComboBoxOptions>([]);
  const [statuses, setStatuses] = useState<ComboBoxOptions>([]);
  const [salesReps, setSalesReps] = useState<ComboBoxOptions>([]);
  const [bdAgents, setBDAgents] = useState<ComboBoxOptions>([]);
  const [managers, setManagers] = useState<ComboBoxOptions>([]);

  const loadDropdownData = useCallback(async () => {
    if (jwt && dealerId > 0) {
      const [fetchedMetadata, fetchedUsers] = await Promise.all([
        getLeadMetaData(dealerId, jwt, true) as Promise<
          LeadMetadata | null | undefined
        >,
        getUsers(dealerId, jwt, true) as Promise<User[] | null | undefined>
      ]);

      setMappedResults(setIntents, fetchedMetadata?.leadIntents);
      setMappedResults(setTypes, fetchedMetadata?.leadTypes);
      setMappedResults(setSources, fetchedMetadata?.leadSources);
      setMappedResults(setStatuses, fetchedMetadata?.leadStatuses);
      setMappedResults(
        setSalesReps,
        fetchedUsers?.filter(s => s.type === UserType.SalesRep)
      );
      setMappedResults(
        setBDAgents,
        fetchedUsers?.filter(s => s.type === UserType.BdAgent)
      );
      setMappedResults(
        setManagers,
        fetchedUsers?.filter(s => s.type === UserType.Manager)
      );
    }
  }, [dealerId, jwt]);

  useEffect(() => {
    loadDropdownData();
  }, [loadDropdownData]);

  function onLeadInformationChange(
    event: InterstateOnChangeEvent<ComboBoxEventValue>,
    key: string,
    options: ComboBoxOptions
  ) {
    if (
      !event.isValid ||
      !event.target.value ||
      !Array.isArray(event.target.value) ||
      event.target.value.length === 0 ||
      !event.target.value[0].value
    ) {
      return;
    }

    const id = options.find(
      obj => obj.value === event.target.value[0].value
    )?.value;

    const value = id ? +id : undefined;

    if (value) {
      setLeadInformation({
        ...leadInformation,
        [key]: value
      });
    }
  }

  return (
    <>
      <h3>Lead</h3>

      <Box
        columnGap={"1em"}
        display={"grid"}
        gridTemplateColumns={"1fr 1fr 1fr"}
        paddingBottom={"1em"}
        rowGap={"1em"}
      >
        <ComboBox
          disabled={true}
          label="Lead Intent"
          name="lead-intent-input"
          options={intents}
          size="small"
          value={getValue(intents, leadInformation.intent)}
          onChange={event => onLeadInformationChange(event, "intent", intents)}
        />

        <ComboBox
          label="Lead Type"
          name="lead-type-input"
          options={types}
          size="small"
          value={getValue(types, leadInformation.type)}
          onChange={event => onLeadInformationChange(event, "type", types)}
        />

        <ComboBox
          label="Lead Source"
          name="lead-source-input"
          options={sources}
          size="small"
          value={getValue(sources, leadInformation.type)}
          onChange={event => onLeadInformationChange(event, "source", sources)}
        />

        {leadInformation.id && leadInformation.id > 0 && (
          <>
            <ComboBox
              label="Lead Status"
              name="lead-status-input"
              options={statuses}
              size="small"
              value={getValue(statuses, leadInformation.status)}
              onChange={event =>
                onLeadInformationChange(event, "status", statuses)
              }
            />

            <ComboBox
              label="Sales Rep"
              name="sales-rep-input"
              options={salesReps}
              size="small"
              value={getValue(salesReps, leadInformation.salesRepId)}
              onChange={event =>
                onLeadInformationChange(event, "salesRepId", salesReps)
              }
            />

            <ComboBox
              label="BD Agent"
              name="bd-agent-input"
              options={bdAgents}
              size="small"
              value={getValue(bdAgents, leadInformation.bdAgentId)}
              onChange={event =>
                onLeadInformationChange(event, "bdAgentId", bdAgents)
              }
            />

            <ComboBox
              label="Manager"
              name="manager-input"
              options={managers}
              size="small"
              value={getValue(managers, leadInformation.managerId)}
              onChange={event =>
                onLeadInformationChange(event, "managerId", managers)
              }
            />

            <TextInput
              readOnly
              id="created-input"
              label="Created"
              name="created-input"
              size="small"
              // TODO: Need to calculate lead age .e.g (10d) and add it to value
              value={leadInformation.created || ""}
            />

            <Box alignContent={"center"} marginTop={"1.5em"}>
              <CheckBox
                id="contacted-input"
                label="Contacted"
                name="contacted-input"
                value={leadInformation.contacted ? "1" : "0"}
                onChange={function (
                  event: InterstateOnChangeEvent<CheckBoxEventValue>
                ): void {
                  setLeadInformation({
                    ...leadInformation,
                    contacted: event.target.value.checked
                  });
                }}
              />
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

export default EditLeadLeadInformation;
