import {
  PortalMessage,
  RegisteredPortal,
  RegisteredPortalFactory
} from "@vinsolutions/utility/portals";
import { AcquisitionLeadPanelPortalProps } from "./interfaces/acquisition-lead-panel-portal-props";
import { AcquisitionLeadPanelPortal } from "./components/acquisition-lead-panel-portal";
import { PortalEventData } from "./interfaces/portal-event-data";

export class AcquisitionLeadPanelPortalFactory
  implements RegisteredPortalFactory<AcquisitionLeadPanelPortalProps>
{
  create({
    originDocument,
    originElement,
    portalId,
    portalKey,
    portalProps
  }: PortalMessage): RegisteredPortal<AcquisitionLeadPanelPortalProps> {
    const dataIsland = (portalProps?.data || {}) as PortalEventData;
    const customerId = dataIsland?.CustomerContext?.CustomerId;
    const selectedLeadId = dataIsland?.CustomerContext?.SelectedLeadId;

    return {
      originDocument,
      originElement,
      portalId,
      portalKey,
      portalComponent: AcquisitionLeadPanelPortal,
      portalComponentProps: {
        customerId,
        portalId,
        selectedLeadId
      }
    };
  }
}
