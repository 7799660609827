/* eslint-disable @cspell/spellchecker -- litsmsicon */

import { Box } from "@interstate/components/Box";
import { PhoneIcon } from "@interstate/components/Icons/PhoneIcon";
import { EnvelopeIcon } from "@interstate/components/Icons/EnvelopeIcon";
import { CalendarIcon } from "@interstate/components/Icons/CalendarIcon";
import { ClipboardIcon } from "@interstate/components/Icons/ClipboardIcon";
import { ChatBubbleLeftIcon } from "@interstate/components/Icons/ChatBubbleLeftIcon";
import { NoSymbolIcon } from "@interstate/components/Icons/NoSymbolIcon";
import { CheckmarkSolidIcon } from "@interstate/components/Icons/CheckmarkSolidIcon";
import { useState } from "react";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AcquisitionLeadComplete } from "@vinsolutions/page/acquisition-lead-complete";
import Cta, { ctaIconFontSize, defaultCtaColor, disabledCtaColor } from "./cta";
import { AcquisitionModal } from "./acquisition-modal";
import { ConsentStatus } from "@vinsolutions/data-access/lead/crm.lead.bff";
import OemWarningModal from "./oem-warning-modal";

export interface LeadPanelCtaProps {
  customerId: number;
  customerConsentStatus: ConsentStatus;
  customerHasValidEmailAddress: boolean;
  dealerId: number;
  dealerHasFlickFusion: boolean;
  dealerHasCASLCompliance: boolean;
  leadId: number;
  leadStatus: number;
  userId: number;
  userName: string;
}

declare global {
  interface Window {
    OpenWindow: (url: string, popupName: string) => void;
    OpenAppointmentWindow: (queryParams: string) => void;
    VSRadOpen: (url: string, templateToUse: string) => void;
  }
}

export function LeadPanelCtas({
  customerId,
  customerConsentStatus,
  customerHasValidEmailAddress,
  dealerId,
  dealerHasFlickFusion,
  dealerHasCASLCompliance,
  leadId,
  leadStatus,
  userId,
  userName
}: LeadPanelCtaProps) {
  const [showFinalize, setShowFinalize] = useState(false);
  const [showOemWarning, setShowOemWarning] = useState(false);
  const [oemWarningCallback, setOemWarningCallback] = useState<() => void>();

  type ActionType = "call" | "email" | "text" | "appointment" | "note";

  function handleAction(actionType: ActionType) {
    const openWindow = () => {
      let url = "";
      let windowName = "";
      const flickFusion = dealerHasFlickFusion ? "&ff=true" : "";

      switch (actionType) {
        case "call":
          url = `/CarDashboard/Pages/leadmanagement/logcall.aspx?AutoLeadID=${leadId}&GlobalCustomerID=${customerId}&V2Redirect=2`;

          windowName = "LogCallEdit";
          break;
        case "email":
          url = `/CarDashboard/Pages/LeadManagement/sendemail.aspx?AutoLeadID=${leadId}&GlobalCustomerID=${customerId}`;

          windowName = "EmailView";
          break;
        case "text":
          url =
            "/CarDashboard/Pages/rims2.aspx?urlSettingName=Communication.VinWFETextingBase.url" +
            `&dealerId=${dealerId}&customerId=${customerId}&autoLeadId=${leadId}&userId=${userId}` +
            `${flickFusion}&username=${userName}`;

          windowName = "TextView";
          break;
        case "appointment":
          url = `?AutoLeadID=${leadId}&GlobalCustomerID=${customerId}&mode=dashboard`;

          windowName = "AppointmentWindow";
          break;
        case "note":
          url = `../../frameploader.aspx?TargetControl=LeadManagement/AddNote.ascx&AutoLeadID=${leadId}&mode=dashboard`;

          windowName = "BasicModalWindow1";
          break;
        default:
          console.error("Invalid action type");
          return;
      }

      window.OpenWindow(url, windowName);
    };

    if (leadStatus === 3) {
      setOemWarningCallback(() => openWindow);
      setShowOemWarning(true);
    } else {
      setOemWarningCallback(undefined);
      openWindow();
    }
  }

  function onBadLost() {
    // TODO: New bad/lost modal
    throw new Error("Function not implemented.");
  }

  function getTextColor(): string {
    if (customerConsentStatus === ConsentStatus.OptedIn) {
      return "green";
    }

    if (customerConsentStatus === ConsentStatus.OptedOut) {
      if (dealerHasCASLCompliance) {
        return disabledCtaColor;
      } else {
        return "red";
      }
    }

    return disabledCtaColor;
  }

  function getTextTooltip(): string {
    switch (customerConsentStatus) {
      case ConsentStatus.OptedIn:
        return "Customer is Opted In";
      case ConsentStatus.OptedOut:
        return "Customer is Opted Out";
      default:
        return "Customer consent status is unknown";
    }
  }

  const showComplete = leadStatus !== 2;
  const showLostBad = leadStatus !== 3;

  return (
    <Box
      alignContent={"center"}
      columnGap="1.5em"
      display={"flex"}
      justifyContent={"space-between"}
      paddingTop={"2em"}
    >
      <Cta
        icon={<PhoneIcon color={defaultCtaColor} fontSize={ctaIconFontSize} />}
        title={"Call"}
        onClick={() => handleAction("call")}
      />

      <Cta
        disabled={!customerHasValidEmailAddress}
        icon={
          <EnvelopeIcon
            color={
              customerHasValidEmailAddress ? defaultCtaColor : disabledCtaColor
            }
            fontSize={ctaIconFontSize}
          />
        }
        title={"Email"}
        tooltip={
          customerHasValidEmailAddress
            ? undefined
            : "Customer does not have a valid email address"
        }
        onClick={() => handleAction("email")}
      />

      <Cta
        disabled={customerConsentStatus !== ConsentStatus.OptedIn}
        icon={
          <ChatBubbleLeftIcon
            color={getTextColor()}
            fontSize={ctaIconFontSize}
          />
        }
        title={"Text"}
        tooltip={getTextTooltip()}
        onClick={() => handleAction("text")}
      />

      <Cta
        icon={
          <CalendarIcon color={defaultCtaColor} fontSize={ctaIconFontSize} />
        }
        title={"Appt"}
        onClick={() => handleAction("appointment")}
      />

      <Cta
        icon={
          <ClipboardIcon color={defaultCtaColor} fontSize={ctaIconFontSize} />
        }
        title={"Note"}
        onClick={() => handleAction("note")}
      />

      {showLostBad && (
        <Cta
          disabled={true} // TODO: Enable when modal is ready
          icon={<NoSymbolIcon color={"red"} fontSize={ctaIconFontSize} />}
          title={"Bad/Lost"}
          onClick={onBadLost}
        />
      )}

      {showComplete && (
        <Cta
          icon={
            <CheckmarkSolidIcon
              color={defaultCtaColor}
              fontSize={ctaIconFontSize}
            />
          }
          title={"Complete"}
          onClick={() => setShowFinalize(true)}
        />
      )}
      <AcquisitionModal
        maxWidth={400}
        modalHeader="Finalize Acquisition Vehicle"
        modalSize={"small"}
        modalVisible={showFinalize}
        setModalVisible={setShowFinalize}
      >
        <AcquisitionLeadComplete
          leadId={leadId}
          showTitle={false}
          onClose={() => setShowFinalize(false)}
        />
      </AcquisitionModal>
      <AcquisitionModal
        modalHeader="Bad/Lost Lead"
        modalSize={"small"}
        modalVisible={showOemWarning && !!oemWarningCallback}
        setModalVisible={setShowOemWarning}
      >
        <OemWarningModal
          onClose={() => setShowOemWarning(false)}
          onContinue={oemWarningCallback}
        />
      </AcquisitionModal>
    </Box>
  );
}

export default LeadPanelCtas;
