/* eslint-disable @cspell/spellchecker -- ymmt */
/* eslint-disable @cspell/spellchecker -- YMMT */

import { Box } from "@interstate/components/Box";
import { useCallback, useState } from "react";
import { LeadPanelVehicleInformationProps } from "../interfaces/vehicle-information-props";
import { Button } from "@interstate/components/Button";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AcquisitionVehicleEdit } from "@vinsolutions/page/acquisition-vehicle-edit";
import TextView, { TextAreaView } from "./text-view";
import { AcquisitionModal } from "./acquisition-modal";

export function LeadPanelVehicleInformation({
  leadId,
  isAcquired,
  vehicleInformation
}: LeadPanelVehicleInformationProps) {
  const [show, setShow] = useState(false);

  const vehicleTitle = useCallback(() => {
    return (
      `${vehicleInformation.year}` +
      ` ${vehicleInformation.make}`.trimEnd() +
      ` ${vehicleInformation.model}`.trimEnd() +
      ` ${vehicleInformation.trim}`.trimEnd() +
      ` ${vehicleInformation.bodyStyle}`.trimEnd() +
      ` ${
        vehicleInformation.doors ? `${vehicleInformation.doors}D` : ""
      }`.trimEnd()
    );
  }, [vehicleInformation]);

  function getButtonTitle() {
    if (isAcquired) {
      return "View";
    }

    return vehicleInformation?.vehicleId ? "Edit" : "Add";
  }

  function getModalHeader() {
    if (isAcquired) {
      return "View Acquired Vehicle";
    }

    return vehicleInformation?.vehicleId
      ? "Edit Acquisition Vehicle"
      : "Add Acquisition Vehicle";
  }

  const vehicleInfo = (
    <Box display={"grid"} gridTemplateColumns={"1fr"}>
      <TextView
        label="Appraised Value"
        value={
          vehicleInformation?.appraisalValue
            ? `$${vehicleInformation.appraisalValue}`
            : ""
        }
      />

      <TextView label="VIN" value={vehicleInformation?.vin} />

      <TextView label="YMMT" value={vehicleTitle()} />

      <TextView label="Transmission" value={vehicleInformation?.trans} />

      <TextView label="Body Style" value={vehicleInformation?.bodyStyle} />

      <TextView label="Mileage" value={vehicleInformation?.mileage} />

      <TextView label="Ext. Color" value={vehicleInformation?.exteriorColor} />

      <TextView label="Int. Color" value={vehicleInformation?.interiorColor} />

      <TextView label="Int. Type" value={vehicleInformation?.interiorType} />

      <TextAreaView label="Notes" rows={3} value={vehicleInformation?.notes} />
    </Box>
  );

  const offerInfo = (
    <Box display={"grid"} gridTemplateColumns={"1fr"}>
      <TextView label="Offer Creation Date" value={"08/08/2024"} />

      <TextView label="Purchase Date" value={"08/08/2024"} />

      <TextView label="Purchase By" value={"Jon Doe"} />

      <TextView label="Original Offer Amount" value={"$17,000.00"} />

      <TextView label="Reconditioning Fees" value={"$18,034.00"} />

      <TextView label="Final Offer Amount" value={"$18,034.00"} />
    </Box>
  );

  // TODO: Move View Acquired Vehicle modal?
  return (
    <Box paddingBottom={"2em"}>
      <Box display={"grid"} gridTemplateColumns={"1fr 1fr"}>
        <h5>{isAcquired ? "Vehicle Acquired" : "Acquisition Vehicle"}</h5>
        <Box textAlign={"right"}>
          <Button
            buttonStyle="aux"
            size={"small"}
            onClick={() => setShow(true)}
          >
            {getButtonTitle()}
          </Button>
          <AcquisitionModal
            modalHeader={getModalHeader()}
            modalSize={"large"}
            modalVisible={show}
            setModalVisible={setShow}
          >
            {isAcquired ? (
              <Box padding={"0 1em 1em 1em"}>
                <Box
                  columnGap={"1em"}
                  display={"grid"}
                  gridTemplateColumns={"2fr 1fr"}
                  rowGap={"1em"}
                >
                  <Box>
                    <h5>Acquisition Vehicle</h5>
                    {vehicleInfo}
                  </Box>
                  <Box>
                    <h5>Offer</h5>
                    {offerInfo}
                  </Box>
                </Box>
              </Box>
            ) : (
              <AcquisitionVehicleEdit
                leadId={leadId}
                showTitle={false}
                onClose={() => setShow(false)}
              />
            )}
          </AcquisitionModal>
        </Box>
      </Box>
      {vehicleInfo}
    </Box>
  );
}

export default LeadPanelVehicleInformation;
