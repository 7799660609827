import { VehicleMake } from "../models/vehicle-make";

export const getVehicleMakesMock: VehicleMake[] = [
  { name: "Lexus", id: 1 },
  { name: "Kia", id: 2 },
  { name: "BMW", id: 3 },
  { name: "Ford", id: 4 },
  { name: "Chevrolet", id: 5 },
  { name: "RAM", id: 6 },
  { name: "Tesla", id: 7 },
  { name: "Jeep", id: 8 }
];
