import { RefObject, useRef } from "react";

/**
 * Custom hook to create and manage a Shadow DOM root.
 *
 * This hook attaches a Shadow DOM root to a specified container element and returns the ShadowRoot instance.
 * It uses a WeakRef to hold references to the ShadowRoot and the container element to avoid memory leaks.
 * The ShadowRoot is created with the specified options (defaulting to { mode: "open" }).
 *
 * @param {RefObject<WeakRef<HTMLElement>>} containerTargetRef - A ref object pointing to the container element to which the Shadow DOM root will be attached.
 * @param {ShadowRootInit} [options={ mode: "open" }] - Options for creating the Shadow DOM root.
 * @returns {ShadowRoot | null} - The created ShadowRoot instance, or null if the container element is not available.
 *
 * @example
 * import { useRef } from "react";
 * import useShadowRoot from "./use-shadow-root";
 *
 * const MyComponent = () => {
 *   const containerRef = useRef(new WeakRef(document.createElement('div')));
 *   const shadowRoot = useShadowRoot(containerRef);
 *
 *   // Use the shadowRoot for rendering content
 * };
 */
const useShadowRoot = (
  containerTargetRef: RefObject<WeakRef<HTMLElement> | null>,
  options: ShadowRootInit = { mode: "open" }
): ShadowRoot | null => {
  const shadowRootRef = useRef<WeakRef<ShadowRoot> | null>(null);

  /**
   * Evaluate the shadow root lazily:
   * - We need first render to have the shadow root element, so it shouldn't happen
   *   in useEffect.
   * - Similar to the portal target, we want the ref to consistently point to the same resource and only create it once per registered portal.
   * @link https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
   */
  const getOrCreateShadowRoot = () => {
    const containerTarget = containerTargetRef.current?.deref();
    if (!shadowRootRef.current?.deref() && containerTarget) {
      if (!containerTarget.shadowRoot) {
        const shadowRoot = containerTarget.attachShadow(options);
        shadowRootRef.current = new WeakRef(shadowRoot);
      } else {
        shadowRootRef.current = new WeakRef(containerTarget.shadowRoot);
      }
    } else if (!containerTarget) {
      shadowRootRef.current = null;
    }
    return shadowRootRef.current?.deref() || null;
  };
  const shadowRoot = getOrCreateShadowRoot();
  return shadowRoot;
};

export default useShadowRoot;
