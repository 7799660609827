import { Button } from "@interstate/components/Button";
import { Box } from "@interstate/components/Box";
import { AddLeadCtasProps } from "../interfaces/lead-ctas-props";
import { updateLead } from "@vinsolutions/data-access/lead/crm.lead.bff";
import { useCallback } from "react";
import { TagManager } from "@vinsolutions/core-third-party-gtm";

export function EditLeadCtas({
  dealerId,
  jwt,
  leadInformation,
  onClose
}: AddLeadCtasProps) {
  // TODO: Add real validation that the lead is savable

  const callUpdateLead = useCallback(async () => {
    if (dealerId > 0 && jwt && leadInformation) {
      // TODO: Update GTM event once requirements are known (or replace with PixAll?)
      TagManager.event({
        event: "userEvent",
        eventElement: "EditLead",
        eventAction: "Save button clicked",
        eventResult: "Saved lead",
        eventLocation: "EditLead"
      });

      const result = await updateLead(jwt, leadInformation, true);

      if (!result) {
        // TODO: Show error message
      }

      if (onClose) {
        onClose();
      }
    }
  }, [dealerId, jwt, leadInformation]);

  return (
    <Box
      columnGap="1.5em"
      display={leadInformation ? "flex" : "none"}
      justifyContent={"flex-end"}
      paddingTop={"1em"}
    >
      {onClose && (
        <Button
          buttonStyle="tertiary"
          data-testid="button-cancel"
          id="cancel-button"
          size="medium"
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
        >
          Cancel
        </Button>
      )}

      <Button
        buttonStyle="primary"
        data-testid="button-create"
        id="create-button"
        size="medium"
        onClick={() => callUpdateLead()}
      >
        Save
      </Button>
    </Box>
  );
}

export default EditLeadCtas;
