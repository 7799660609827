import PortalStyleManager from "./portal-style-provider";

type TopPortalStyleProviderProps = {
  cacheKey: string;
  children: React.ReactNode;
};

/**
 * Ensures styled component providers are pointed to the window's top document. Useful when a modal or other component is rendered into the top document from a portal that exists in another frame.
 * @param cacheKey - A unique key to identify the cache provider for the rendered component.
 * @param children - The children to render.
 */
export const TopPortalStyleProvider = ({
  cacheKey,
  children
}: TopPortalStyleProviderProps) => {
  return (
    <PortalStyleManager
      cacheKey={cacheKey}
      targetContainer={window.top?.document.head}
    >
      {children}
    </PortalStyleManager>
  );
};

export default TopPortalStyleProvider;
