import { Box } from "@interstate/components/Box";
import {
  LeadMetadata,
  User,
  UserType,
  getLeadMetaData,
  getUsers,
  setMappedResults
} from "@vinsolutions/data-access/lead/crm.lead.bff";
import { useCallback, useEffect, useState } from "react";
import { LeadPanelLeadInformationProps } from "../interfaces/lead-information-props";
import { Button } from "@interstate/components/Button";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AcquisitionLeadEdit } from "@vinsolutions/page/acquisition-lead-edit";
import TextView, { getLabelByValue } from "./text-view";
import { AcquisitionModal } from "./acquisition-modal";
import { ComboBoxOptions } from "@interstate/components/ComboBox";

export function LeadPanelLeadInformation({
  dealerId,
  jwt,
  leadInfromation
}: LeadPanelLeadInformationProps) {
  const [show, setShow] = useState(false);

  const [sources, setSources] = useState<ComboBoxOptions>([]);
  const [statuses, setStatuses] = useState<ComboBoxOptions>([]);
  const [salesReps, setSalesReps] = useState<ComboBoxOptions>([]);
  const [bdAgents, setBDAgents] = useState<ComboBoxOptions>([]);
  const [managers, setManagers] = useState<ComboBoxOptions>([]);

  const loadDropdownData = useCallback(async () => {
    if (jwt && dealerId > 0) {
      const [fetchedMetadata, fetchedUsers] = await Promise.all([
        getLeadMetaData(dealerId, jwt, true) as Promise<
          LeadMetadata | null | undefined
        >,
        getUsers(dealerId, jwt, true) as Promise<User[] | null | undefined>
      ]);

      setMappedResults(setSources, fetchedMetadata?.leadSources);
      setMappedResults(setStatuses, fetchedMetadata?.leadStatuses);
      setMappedResults(
        setSalesReps,
        fetchedUsers?.filter(s => s.type === UserType.SalesRep)
      );
      setMappedResults(
        setBDAgents,
        fetchedUsers?.filter(s => s.type === UserType.BdAgent)
      );
      setMappedResults(
        setManagers,
        fetchedUsers?.filter(s => s.type === UserType.Manager)
      );
    }
  }, [dealerId, jwt]);

  useEffect(() => {
    loadDropdownData();
  }, [loadDropdownData]);

  return (
    <Box>
      <Box display={"grid"} gridTemplateColumns={"1fr 1fr"}>
        <h5>Lead</h5>
        <Box textAlign={"right"}>
          <Button
            buttonStyle="aux"
            size={"small"}
            onClick={() => setShow(true)}
          >
            Edit
          </Button>

          <AcquisitionModal
            modalHeader="Edit Lead"
            modalSize={"large"}
            modalVisible={show}
            setModalVisible={setShow}
          >
            <AcquisitionLeadEdit
              leadId={leadInfromation.id}
              showTitle={false}
              onClose={() => setShow(false)}
            />
          </AcquisitionModal>
        </Box>
      </Box>
      <Box display={"grid"} gridTemplateColumns={"1fr"} paddingTop={"2px"}>
        <TextView
          label="Lead Status"
          value={getLabelByValue(statuses, leadInfromation?.status)}
        />

        <TextView
          label="Sales Rep"
          value={getLabelByValue(salesReps, leadInfromation?.salesRepId)}
        />

        <TextView
          label="BD Agent"
          value={getLabelByValue(bdAgents, leadInfromation?.bdAgentId)}
        />

        <TextView
          label="Manager"
          value={getLabelByValue(managers, leadInfromation?.managerId)}
        />

        <TextView label="Created" value={leadInfromation?.created} />

        <TextView
          label="Source"
          value={getLabelByValue(sources, leadInfromation?.source)}
        />

        <TextView
          label="Contacted"
          value={leadInfromation?.contacted ? "Yes" : "No"}
        />
      </Box>
    </Box>
  );
}

export default LeadPanelLeadInformation;
