import {
  PortalMessage,
  RegisteredPortal,
  RegisteredPortalFactory
} from "@vinsolutions/utility/portals";
import { PortalEventData } from "./interfaces/portal-event-data";
import { AcquisitionLeadTabPortalProps } from "./interfaces/acquisition-lead-tab-portal-props";
import { AcquisitionLeadTab } from "./components/acquisition-lead-tab";

export class AcquisitionLeadTabPortalFactory
  implements RegisteredPortalFactory<AcquisitionLeadTabPortalProps>
{
  create({
    originDocument,
    originElement,
    portalId,
    portalKey,
    portalProps
  }: PortalMessage): RegisteredPortal<AcquisitionLeadTabPortalProps> {
    const dataIsland = (portalProps?.data || {}) as PortalEventData;
    const customerId = dataIsland?.CustomerContext?.CustomerId;

    return {
      originDocument,
      originElement,
      portalId,
      portalKey,
      portalComponent: AcquisitionLeadTab,
      portalComponentProps: {
        customerId,
        portalId
      }
    };
  }
}
