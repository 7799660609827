import { Box } from "@interstate/components/Box";
import { useCallback } from "react";
import styled from "styled-components";
import { CompleteVehicleInformationProps } from "../interfaces/vehicle-information-props";

const StyledLabel = styled.div`
  color: darkgray;
`;

const StyledValue = styled.div``;

export function CompleteVehicleInformation({
  vehicleInformation
}: CompleteVehicleInformationProps) {
  const vehicleTitle = useCallback(() => {
    return (
      `${vehicleInformation.year}` +
      ` ${vehicleInformation.make}`.trimEnd() +
      ` ${vehicleInformation.model}`.trimEnd() +
      ` ${vehicleInformation.trim}`.trimEnd() +
      ` ${vehicleInformation.bodyStyle}`.trimEnd() +
      ` ${
        vehicleInformation.doors ? `${vehicleInformation.doors}D` : ""
      }`.trimEnd()
    );
  }, [vehicleInformation]);

  return (
    <Box paddingTop={"1em"}>
      <h4>{vehicleTitle()}</h4>

      <Box
        columnGap={"1em"}
        display={"grid"}
        gridTemplateColumns={"1fr 1fr"}
        paddingTop={"1em"}
        rowGap={"1em"}
      >
        <StyledLabel>VIN</StyledLabel>
        <StyledValue>{vehicleInformation.vin}</StyledValue>

        <StyledLabel>Mileage</StyledLabel>
        <StyledValue>{vehicleInformation.mileage}</StyledValue>

        <StyledLabel>Ext. Color</StyledLabel>
        <StyledValue>{vehicleInformation.exteriorColor}</StyledValue>
      </Box>
    </Box>
  );
}

export default CompleteVehicleInformation;
