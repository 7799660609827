import { Box } from "@interstate/components/Box";
import { useCallback, useEffect, useState } from "react";
import {
  VehicleInformation,
  getVehicleInformation
} from "@vinsolutions/data-access/lead/crm.lead.bff";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { getProfileState } from "@vinsolutions/ccrm/store";
import { shallowEqual, useSelector } from "react-redux";
import { useNewRelicInteraction } from "@vinsolutions/core-third-party-newrelic";
import { AcquisitionLeadCompleteProps } from "./interfaces/acquisition-lead-complete-props";
import { useLocation } from "react-router-dom";
import CompleteVehicleInformation from "./components/vehicle-information";
import CompleteAcquisitionInformation from "./components/acquisition-information";
import CompleteLeadCtas from "./components/complete-lead-ctas";
import styled from "styled-components";

/**
 * Hides a third div that throws off styling. This will be fixed in Interstate 1.1.0, so this can potentially be removed after that version
 */
const InterstateInputStyleFix = styled.div`
  &&& div.ids-text-input-container.MuiBox-root,
  div.ids-combo-box-root-container.MuiBox-root {
    div.MuiGrid2-container
      div.MuiGrid2-root.MuiGrid2-direction-xs-row:nth-child(3) {
      display: none;
    }
  }
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function AcquisitionLeadComplete({
  leadId: stateLeadId,
  showTitle,
  onClose
}: AcquisitionLeadCompleteProps) {
  const query = useQuery();

  const { endInteraction, setInteractionAttributes } = useNewRelicInteraction(
    "PageCompleteLead",
    "Loading Complete Lead"
  );

  const [leadId, setLeadId] = useState<number>(stateLeadId || 0);

  const [vehicleInformation, setVehicleInformation] =
    useState<VehicleInformation>({} as VehicleInformation);

  const { dealerId: profileDealerId, userId: profileUserId } = useSelector(
    getProfileState,
    shallowEqual
  );

  const oidcUser = useReactOidc().oidcUser;

  if (leadId <= 0) {
    const queryLeadId = +(
      query.get("leadId") ||
      query.get("AutoLeadID") ||
      "0"
    );

    if (queryLeadId > 0) {
      setLeadId(queryLeadId);
    } else {
      throw new Error("leadId parameter is required");
    }
  }

  const dealerId = profileDealerId || 0;
  const userId = profileUserId || 0;

  setInteractionAttributes({
    leadId,
    dealerId
  });

  const loadVehicle = useCallback(async () => {
    const result =
      oidcUser && leadId > 0
        ? await getVehicleInformation(leadId, oidcUser.access_token, true)
        : null;

    if (result) {
      setVehicleInformation(result);
    }
  }, [leadId, oidcUser, setVehicleInformation]);

  useEffect(() => {
    loadVehicle();
  }, [leadId, loadVehicle]);

  const handlePageLoad = useCallback(() => {
    endInteraction();
  }, [endInteraction]);

  // TODO: Not working, fix it
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.addEventListener("load", handlePageLoad);

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.removeEventListener("load", handlePageLoad);
    };
  }, []);

  return (
    <InterstateInputStyleFix>
      <Box padding={showTitle ? "1.75em 1em 1em 1em" : "0 1em 1em 1em"}>
        {showTitle && <h3>Finalize Acquisition Vehicle</h3>}

        <CompleteVehicleInformation vehicleInformation={vehicleInformation} />

        <CompleteAcquisitionInformation
          dealerId={dealerId}
          jwt={oidcUser.access_token}
          userId={userId}
          vehicleInformation={vehicleInformation}
        />

        <CompleteLeadCtas
          dealerId={dealerId}
          jwt={oidcUser.access_token}
          leadId={leadId}
          onClose={onClose}
        />
      </Box>
    </InterstateInputStyleFix>
  );
}
