import { Button } from "@interstate/components/Button";
import { TextInput } from "@interstate/components/TextInput";
import { TextArea } from "@interstate/components/TextArea";
import { InterstateOnChangeEvent } from "@interstate/components/InterstateEvents";
import { useCallback, useEffect, useState } from "react";
import { Action } from "@interstate/components/Action";
import { PlusSmallIcon } from "@interstate/components/Icons/PlusSmallIcon";
import { MinusSmallIcon } from "@interstate/components/Icons/MinusSmallIcon";
import { Box } from "@interstate/components/Box";
import {
  getVehicleInformationFromVin,
  getVehicleMakes,
  getVehicleModels,
  getVehicleTrims,
  getVehicleYears,
  setMappedResults
} from "@vinsolutions/data-access/lead/crm.lead.bff";
import { TagManager } from "@vinsolutions/core-third-party-gtm";
import { EditVehicleVehicleInformationProps } from "../interfaces/vehicle-information-props";
import { PriceInput } from "@interstate/components/PriceInput";
import {
  ComboBox,
  ComboBoxEventValue,
  ComboBoxOption,
  ComboBoxOptions
} from "@interstate/components/ComboBox";

function getValue(
  options: ComboBoxOptions,
  selectedLabel: string | number | null
): ComboBoxOptions | undefined {
  const selectedOption = options.find(
    s => s.label === selectedLabel?.toString()
  );

  return selectedOption ? [selectedOption] : undefined;
}

export function EditVehicleVehicleInformation({
  dealerId,
  isAdding,
  jwt,
  vehicleInformation,
  setVehicleInformation
}: EditVehicleVehicleInformationProps) {
  const [expanded, setExpanded] = useState(false);

  const [years, setYears] = useState<ComboBoxOptions>([]);
  const [makes, setMakes] = useState<ComboBoxOptions>([]);
  const [models, setModels] = useState<ComboBoxOptions>([]);
  const [trims, setTrims] = useState<ComboBoxOptions>([]);
  const [selectedMake, setSelectedMake] = useState<string | null>(
    vehicleInformation.make
  );
  const [selectedModel, setSelectedModel] = useState<string | null>(
    vehicleInformation.model
  );

  const loadYears = useCallback(async () => {
    const result =
      jwt && dealerId > 0 ? await getVehicleYears(dealerId, jwt, true) : null;

    setYears(
      result
        ? result.map<ComboBoxOption>(year => ({
            value: year.toString(),
            label: year.toString()
          }))
        : []
    );
  }, [dealerId, jwt, setYears]);

  const loadMakes = useCallback(async () => {
    const result =
      jwt && dealerId > 0 ? await getVehicleMakes(dealerId, jwt, true) : null;

    setMappedResults(setMakes, result);
  }, [dealerId, jwt, setMakes]);

  const loadModels = useCallback(async () => {
    const makeId = +(makes.find(obj => obj.label === selectedMake)?.value || 0);

    const result =
      jwt && dealerId > 0 && makeId > 0
        ? await getVehicleModels(dealerId, jwt, makeId, true)
        : null;

    setMappedResults(setModels, result);
  }, [dealerId, jwt, makes, selectedMake, setModels]);

  const loadTrims = useCallback(async () => {
    const makeId = +(makes.find(obj => obj.label === selectedMake)?.value || 0);

    const modelId = +(
      models.find(obj => obj.label === selectedModel)?.value || 0
    );

    const result =
      jwt && dealerId > 0 && makeId > 0 && modelId > 0
        ? await getVehicleTrims(dealerId, jwt, makeId, modelId, true)
        : null;

    setMappedResults(setTrims, result);
  }, [dealerId, jwt, makes, models, selectedMake, selectedModel, setTrims]);

  const decodeVin = useCallback(async () => {
    const result =
      jwt && vehicleInformation.vin
        ? await getVehicleInformationFromVin(vehicleInformation.vin, jwt, true)
        : null;

    if (result) {
      setVehicleInformation(result);
    } else {
      // TODO: Show error message
    }
  }, [dealerId, jwt, makes, models, setVehicleInformation, vehicleInformation]);

  useEffect(() => {
    loadYears();
    loadMakes();
  }, [loadYears, loadMakes]);

  useEffect(() => {
    loadModels();
  }, [loadModels, selectedMake]);

  useEffect(() => {
    loadTrims();
  }, [loadTrims, selectedModel]);

  useEffect(() => {
    if (
      selectedMake !== vehicleInformation.make ||
      vehicleInformation.make === undefined
    ) {
      setSelectedMake(vehicleInformation.make);
    }

    if (
      selectedModel !== vehicleInformation.model ||
      vehicleInformation.model === undefined
    ) {
      setSelectedModel(vehicleInformation.model);
    }
  }, [
    selectedMake,
    selectedModel,
    setSelectedMake,
    setSelectedModel,
    vehicleInformation
  ]);

  function onVehicleInformationChange(
    event: InterstateOnChangeEvent<ComboBoxEventValue>,
    key: string,
    options: ComboBoxOptions
  ) {
    if (
      !event.isValid ||
      !event.target.value ||
      !Array.isArray(event.target.value) ||
      event.target.value.length === 0 ||
      !event.target.value[0].value
    ) {
      return;
    }

    const label = options.find(
      obj => obj.value === event.target.value[0].value
    )?.label;

    if (label) {
      if (key === "model") {
        setSelectedModel(label);
      }

      if (key === "make") {
        setSelectedMake(label);
      }

      setVehicleInformation({
        ...vehicleInformation,
        [key]: label
      });
    }
  }

  const vinInput = (
    <TextInput
      id="vin-input"
      label="VIN"
      maxLength={17}
      name="vin-input"
      size="small"
      value={vehicleInformation.vin || ""}
      onChange={function (event: InterstateOnChangeEvent<string>): void {
        setVehicleInformation({
          ...vehicleInformation,
          vin: event.target.value.trim()
        });
      }}
    />
  );

  const decodeButton = (
    <Button
      buttonStyle="secondary"
      data-testid="button-decode"
      disabled={(vehicleInformation.vin || "").trim().length < 17}
      id="decode-button"
      size="small"
      onClick={() => {
        // TODO: Update GTM event once requirements are known (or replace with PixAll?)
        TagManager.event({
          event: "userEvent",
          eventElement: "Decode",
          eventAction: "Decode Vin",
          eventResult: "Vin Decoded",
          eventLocation: "EditVehicle"
        });

        return decodeVin();
      }}
    >
      Decode
    </Button>
  );

  const yearInput = (
    <ComboBox
      disabled={!years}
      label="Year"
      name="year-input"
      options={years}
      size="small"
      value={getValue(years, vehicleInformation.year)}
      onChange={event => onVehicleInformationChange(event, "year", years)}
    />
  );

  const makeInput = (
    <ComboBox
      disabled={!vehicleInformation.year}
      label="Make"
      name="make-input"
      options={makes}
      size="small"
      value={getValue(makes, vehicleInformation.make)}
      onChange={event => onVehicleInformationChange(event, "make", makes)}
    />
  );

  const modelInput = (
    <ComboBox
      disabled={!vehicleInformation.make}
      label="Model"
      name="model-input"
      options={models}
      size="small"
      value={getValue(models, vehicleInformation.model)}
      onChange={event => onVehicleInformationChange(event, "model", models)}
    />
  );

  const trimInput = (
    <ComboBox
      disabled={!vehicleInformation.model}
      label="Trim"
      name="trim-input"
      options={trims}
      size="small"
      value={getValue(trims, vehicleInformation.trim)}
      onChange={event => onVehicleInformationChange(event, "trim", trims)}
    />
  );

  const mileageInput = (
    <TextInput
      id="mileage-input"
      label="Mileage"
      name="mileage-input"
      size="small"
      value={vehicleInformation.mileage || ""}
      onBlur={function (event: InterstateOnChangeEvent<string>): void {
        setVehicleInformation({
          ...vehicleInformation,
          mileage: event.target.value
        });
      }}
    />
  );

  const appraisedValueInput = (
    <PriceInput
      id="appraisal-vehicle-input"
      label="Appraisal Value"
      name="appraisal-vehicle-input"
      size="small"
      value={vehicleInformation.appraisalValue || ""}
      onBlur={function (event: InterstateOnChangeEvent<string>): void {
        setVehicleInformation({
          ...vehicleInformation,
          appraisalValue: event.target.value
        });
      }}
    />
  );

  const notesInput = (
    <TextArea
      label="Notes"
      name="notes-input"
      rows={4}
      size="small"
      value={vehicleInformation.notes || ""}
      onBlur={function (event: InterstateOnChangeEvent<string>): void {
        setVehicleInformation({
          ...vehicleInformation,
          notes: event.target.value
        });
      }}
    />
  );

  const doorsInput = (
    <TextInput
      id="doors-input"
      label="Doors"
      name="doors-input"
      size="small"
      value={vehicleInformation.doors || ""}
      onBlur={function (event: InterstateOnChangeEvent<string>): void {
        setVehicleInformation({
          ...vehicleInformation,
          doors: event.target.value
        });
      }}
    />
  );

  const transInput = (
    <TextInput
      id="trans-input"
      label="Trans"
      name="trans-input"
      size="small"
      value={vehicleInformation.trans || ""}
      onBlur={function (event: InterstateOnChangeEvent<string>): void {
        setVehicleInformation({
          ...vehicleInformation,
          trans: event.target.value
        });
      }}
    />
  );

  const engineInput = (
    <TextInput
      id="engine-input"
      label="Engine"
      name="engine-input"
      size="small"
      value={vehicleInformation.engine || ""}
      onBlur={function (event: InterstateOnChangeEvent<string>): void {
        setVehicleInformation({
          ...vehicleInformation,
          engine: event.target.value
        });
      }}
    />
  );

  const bodyStyleInput = (
    <TextInput
      id="body-style-input"
      label="Body Style"
      name="body-style-input"
      size="small"
      value={vehicleInformation.bodyStyle || ""}
      onBlur={function (event: InterstateOnChangeEvent<string>): void {
        setVehicleInformation({
          ...vehicleInformation,
          bodyStyle: event.target.value
        });
      }}
    />
  );

  const exteriorColorInput = (
    <TextInput
      id="exterior-color-input"
      label="Exterior Color"
      name="exterior-color-input"
      size="small"
      value={vehicleInformation.exteriorColor || ""}
      onBlur={function (event: InterstateOnChangeEvent<string>): void {
        setVehicleInformation({
          ...vehicleInformation,
          exteriorColor: event.target.value
        });
      }}
    />
  );

  const interiorColorInput = (
    <TextInput
      id="interior-color-input"
      label="Interior Color"
      name="interior-color-input"
      size="small"
      value={vehicleInformation.interiorColor || ""}
      onBlur={function (event: InterstateOnChangeEvent<string>): void {
        setVehicleInformation({
          ...vehicleInformation,
          interiorColor: event.target.value
        });
      }}
    />
  );

  const interiorTypeInput = (
    <TextInput
      id="interior-type-input"
      label="Interior Type"
      name="interior-type-input"
      size="small"
      value={vehicleInformation.interiorType || ""}
      onBlur={function (event: InterstateOnChangeEvent<string>): void {
        setVehicleInformation({
          ...vehicleInformation,
          interiorType: event.target.value
        });
      }}
    />
  );

  const onSetExpanded = () => {
    // TODO: Update GTM event once requirements are known (or replace with PixAll?)
    TagManager.event({
      event: "userEvent",
      eventElement: "Accordian",
      eventAction: !expanded ? "Expanded" : "Collapsed",
      eventResult: `${!expanded ? "Expanded" : "Collapsed"} accordian`,
      eventLocation: "EditVehicle"
    });

    return setExpanded(expanded => !expanded);
  };

  const addingLayout = (
    <Box paddingTop={"1em"}>
      <h3>Acquisition Vehicle</h3>

      <Box
        alignItems={"flex-end"}
        borderBottom={"1px solid lightgrey"}
        columnGap={"1em"}
        display={"flex"}
        paddingBottom={"1em"}
      >
        {vinInput}
        {decodeButton}
      </Box>

      <Box
        columnGap={"1em"}
        display={"grid"}
        gridTemplateColumns={"1fr 1fr"}
        paddingBottom={"1em"}
        paddingTop={"1em"}
        rowGap={"1em"}
      >
        {yearInput}
        {makeInput}
        {modelInput}
        {trimInput}
        {mileageInput}
        {appraisedValueInput}

        <Box gridColumn={"span 2"}>{notesInput}</Box>
      </Box>

      <Action
        actionIcon={{
          icon: expanded ? <MinusSmallIcon /> : <PlusSmallIcon />,
          start: true
        }}
        onClick={onSetExpanded}
      >
        {expanded ? "See Less" : "See More"}
      </Action>

      <Box
        columnGap={"1em"}
        display={expanded ? "grid" : "none"}
        gridTemplateColumns={"1fr 1fr"}
        paddingBottom={"1em"}
        paddingTop={"1em"}
        rowGap={"1em"}
      >
        {doorsInput}
        {transInput}
        {engineInput}
        {bodyStyleInput}
        {exteriorColorInput}
        {interiorColorInput}
        {interiorTypeInput}
      </Box>
    </Box>
  );

  const editingLayout = (
    <Box paddingTop={"1em"}>
      <Box
        borderBottom={"1px solid lightgrey"}
        columnGap={"1em"}
        display={"grid"}
        gridTemplateColumns={"1fr 1fr"}
        paddingBottom={"1em"}
        rowGap={"1em"}
      >
        {appraisedValueInput}

        <Box
          alignItems={"flex-end"}
          columnGap={"1em"}
          display={"flex"}
          paddingBottom={"1em"}
        >
          {vinInput}
          {decodeButton}
        </Box>
      </Box>

      <Box
        columnGap={"1em"}
        display={"grid"}
        gridTemplateColumns={"1fr 1fr"}
        paddingBottom={"1em"}
        paddingTop={"1em"}
        rowGap={"1em"}
      >
        {yearInput}
        {makeInput}
        {modelInput}
        {trimInput}
        {mileageInput}
        {doorsInput}
        {transInput}
        {engineInput}
        {bodyStyleInput}
        {exteriorColorInput}
        {interiorColorInput}
        {interiorTypeInput}
        <Box gridColumn={"span 2"}>{notesInput}</Box>
      </Box>
    </Box>
  );

  return isAdding ? addingLayout : editingLayout;
}

export default EditVehicleVehicleInformation;
