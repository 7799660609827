import { LeadInformation } from "../models/lead-information";

export const getLeadInformationMock: LeadInformation[] = [
  {
    id: 1,
    created: "07/10/2024 3:17 PM",
    customerId: 1,
    intent: 1,
    type: 1,
    source: 1,
    status: 1,
    salesRepId: 1,
    bdAgentId: 2,
    managerId: 3,
    contacted: true
  },
  {
    id: 2,
    created: "07/10/2024 3:17 PM",
    customerId: 1,
    intent: 1,
    type: 1,
    source: 1,
    status: 2,
    salesRepId: 1,
    bdAgentId: 2,
    managerId: 3,
    contacted: true
  },
  {
    id: 3,
    created: "07/10/2024 3:17 PM",
    customerId: 1,
    intent: 1,
    type: 1,
    source: 1,
    status: 3,
    salesRepId: 1,
    bdAgentId: 2,
    managerId: 3,
    contacted: true
  }
];
