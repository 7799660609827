import { ComboBoxOptions } from "@interstate/components/ComboBox";
import { TextArea } from "@interstate/components/TextArea";
import { TextInput } from "@interstate/components/TextInput";
import styled from "styled-components";

export const StyledInput = styled.div`
  && .ids-text-input {
    gap: 2px;
  }

  && .ids-text-input-readonly {
    padding: 0px 0px 0.75em 0px;
  }
`;

export interface TextViewProps {
  name?: string;
  label: string;
  value?: string | null;
}

export interface TextAreaProps extends TextViewProps {
  rows?: number;
}

export function getLabelByValue(
  options: ComboBoxOptions,
  selectedValue?: string | number | null
) {
  const label = options.find(
    obj => obj.value === selectedValue?.toString()
  )?.label;

  return label;
}

export function TextView({ name, label, value }: TextViewProps) {
  return (
    <StyledInput>
      <TextInput
        readOnly
        label={label}
        name={name ?? label}
        size={"small"}
        value={value || ""}
      />
    </StyledInput>
  );
}

export function TextAreaView({ name, label, value, rows }: TextAreaProps) {
  return (
    <StyledInput>
      <TextArea
        readOnly
        label={label}
        name={name ?? label}
        rows={rows}
        size={"small"}
        value={value || ""}
      />
    </StyledInput>
  );
}

export default TextView;
