import { ModalDialog } from "@vinsolutions/core-cx";
import { TopPortalStyleProvider } from "@vinsolutions/utility/portals";
import styled from "styled-components";

export interface KeyInformationModalProps {
  children: JSX.Element | JSX.Element[];
  htmlId?: string;
  showModal: boolean;
  onHide: () => void;
}

const StyledModalDialog = styled(ModalDialog)`
  padding-top: 170px;

  .modal-dialog.modal-dialog,
  .modal-content.modal-content {
    min-height: 650px;
    min-width: 60vw;
    .modal-header {
      padding: 8px 16px 0 16px;
      .close {
        margin-top: 2px;
      }
    }
  }
`;

export const KeyInformationBannerModal = ({
  children,
  htmlId,
  showModal,
  onHide
}: KeyInformationModalProps) => {
  return (
    // Modal renders to top parent, so ensure we're targeting top in case the banner in being rendered in a portal
    <TopPortalStyleProvider cacheKey="keyinformation-modal">
      <StyledModalDialog
        data-testid="keyInfo-Modal-Dialog"
        htmlId={htmlId || "keyInfo-Modal-Dialog"}
        show={showModal}
        onHide={onHide}
      >
        {children}
      </StyledModalDialog>
    </TopPortalStyleProvider>
  );
};

export default KeyInformationBannerModal;
