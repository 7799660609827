// eslint-disable-next-line @nx/enforce-module-boundaries
import { KeyInformationPortalFactory } from "@vinsolutions/feature/key-information";
import {
  AcquisitionLeadPanelPortalFactory,
  AcquisitionLeadTabPortalFactory
} from "@vinsolutions/sub-feature/acquisition-lead";
import { CustomerDashboardCommunicationsButtonPortalFactory } from "@vinsolutions/sub-feature/unified-inbox";

const VinconnectPortalFactoryRegistry = {
  "acquisition-lead-panel": new AcquisitionLeadPanelPortalFactory(),
  "acquisition-lead-tab": new AcquisitionLeadTabPortalFactory(),
  "portal-customer-dashboard-communications-button":
    new CustomerDashboardCommunicationsButtonPortalFactory(),
  "portal-key-information": new KeyInformationPortalFactory(),
  unknown: null
};

export default VinconnectPortalFactoryRegistry;
