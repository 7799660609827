import {
  PortalMessage,
  RegisteredPortal,
  RegisteredPortalFactory
} from "@vinsolutions/utility/portals";
import KeyInformationPortalInterface, {
  KeyInformationPortalProps
} from "./key-information-portal-interface";
import { PortalEventData } from "./PortalEventData";

const getPortalParentFromLocation = (location: Location | undefined) => {
  return location?.pathname
    ?.substring(location.pathname.lastIndexOf("/") + 1) // page part
    ?.split(".")[0] // remove .aspx
    ?.replace(/([A-Z])/g, " $1") // apply spaces between camel-casings
    ?.trim();
};

export class KeyInformationPortalFactory
  implements RegisteredPortalFactory<KeyInformationPortalProps>
{
  create({
    originDocument,
    originElement,
    portalId,
    portalKey,
    portalProps
  }: PortalMessage): RegisteredPortal<KeyInformationPortalProps> {
    const dataIsland = (portalProps?.data || {}) as PortalEventData;
    const portalParentLocation = getPortalParentFromLocation(
      originDocument?.deref()?.location
    );
    const keyInfoProps = {
      ...(portalProps || {}),
      customerContext: dataIsland?.CustomerContext,
      dealerContext: dataIsland?.DealerContext,
      portalParentLocation
    } as KeyInformationPortalProps;
    return {
      originDocument,
      originElement,
      portalId,
      portalKey,
      portalComponent: KeyInformationPortalInterface,
      portalComponentProps: keyInfoProps
    };
  }
}
