import { ReactNode } from "react";
import { ModalSizes } from "@interstate/components/Modal/Types/modalTypes";
import { Modal } from "@interstate/components/Modal";
import styled from "styled-components";
import { TopPortalStyleProvider } from "@vinsolutions/utility/portals";

const StyledModal = styled(Modal)<{ maxWidth?: number }>`
  ${props => {
    if (props.maxWidth) {
      return `&& .MuiDialog-paperWidthSm { max-width: ${props.maxWidth}px; }`;
    }
    return "";
  }};

  .MuiDialogActions-root {
    display: none;
  }
`;

export interface AcquisitionModalProps {
  /**
   * Children that will display in the body of the modal
   */
  children?: ReactNode;

  maxWidth?: number;

  modalHeader?: string | ReactNode;

  modalVisible: boolean;

  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;

  /**
   * The modal dialog size
   */
  modalSize: ModalSizes;
}

export function AcquisitionModal({
  children,
  maxWidth,
  modalHeader,
  modalVisible,
  setModalVisible,
  modalSize
}: AcquisitionModalProps) {
  return (
    <TopPortalStyleProvider cacheKey="acquisition-modal">
      <StyledModal
        header={modalHeader}
        maxWidth={maxWidth}
        padding={false}
        show={modalVisible}
        size={modalSize}
        onHide={() => setModalVisible(false)}
      >
        {children}
      </StyledModal>
    </TopPortalStyleProvider>
  );
}
