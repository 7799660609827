import { usePortalsContext } from "../state/portals-context";
import Portal from "./Portal";
import { FC } from "react";

type PortalsContainerProps = {
  portalContainerId: string;
  /** CSS applied to created portal container elements.
   * Useful when needing to apply styles defined outside the document the portal is being rendered in */
  portalCss?: string | null;
};

const PortalsContainer: FC<PortalsContainerProps> = ({
  portalContainerId,
  portalCss
}: PortalsContainerProps) => {
  const { registeredPortals } = usePortalsContext();

  return registeredPortals && Object.keys(registeredPortals).length ? (
    <div data-testid={portalContainerId} style={{ display: "none" }}>
      {Object.values(registeredPortals).map(registeredPortal => {
        return (
          <Portal
            key={registeredPortal.portalKey}
            portalCss={portalCss}
            registeredPortal={registeredPortal}
          />
        );
      })}
    </div>
  ) : null;
};

export default PortalsContainer;
