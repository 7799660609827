import { PortalComponentProps } from "@vinsolutions/utility/portals";
import { FeatureKeyInformation } from "../feature-key-information";
import { CustomerContext, DealerContext } from "./PortalEventData";

/* eslint-disable-next-line */
export interface KeyInformationPortalProps extends PortalComponentProps {
  customerContext?: CustomerContext;
  dealerContext?: DealerContext;
  portalParentLocation?: string;
}

const KeyInformationPortalInterface = ({
  customerContext,
  dealerContext,
  portalParentLocation
}: KeyInformationPortalProps) => {
  return (
    <div>
      <FeatureKeyInformation
        activeLeadIds={customerContext?.ActiveLeadIds}
        customerId={customerContext?.CustomerId}
        dealerId={dealerContext?.DealerId || 0}
        gtmEventLocationParent={portalParentLocation}
        selectedLeadId={customerContext?.SelectedLeadId}
      />
    </div>
  );
};

export default KeyInformationPortalInterface;
