import { AcquisitionLeadAddPage } from "@vinsolutions/page/acquisition-lead-add";
import { RouteBuilder } from "../route-builder";

export const acquisitionLeadAddRoute = RouteBuilder.create()
  .withRouteId("vinconnect", "add-lead")
  .withFeatureFlag("nx.vinconnect.vehicle-acquisition.add-lead.enable")
  .withLeftPaneComponent(<AcquisitionLeadAddPage />)
  .withRightPaneComponent("/CarDashboard/Pages/CRM/CustomerDashboard.aspx", {
    requiredQueryParams: ["GlobalCustomerID"]
  })
  .build();
