import { cardashboardActions } from "@vinsolutions/legacy-cardashboard/store";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { createLogger } from "@vinsolutions/logger";
import {
  NavigationEntity,
  NavigationItemsEntity
} from "@vinsolutions/ccrm/interfaces";
import {
  CarDashboardFrame,
  CarDashboardWindow
} from "@vinsolutions/legacy-cardashboard/interfaces";
import {
  crmNavigationItemEntityIds,
  deriveMainNavigation,
  deriveViewingAs,
  generateDuplicateNavigationItems,
  getCommunicationsNavigationItemEntities,
  getVinconnectServerName,
  overrideJavascriptUrls,
  overrideNavigationUrls
} from "@vinsolutions/ccrm/util";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  addNavigationItems,
  blocklistNavItems,
  deskingDealListNavItems
} from "@vinsolutions/legacy-cardashboard/cardashboard-iframe/feature";
import { addDynamicMenuItems, useDynamicHeader } from "../dynamicHeader";
import {
  fetchNotifications,
  getMainNavigationState,
  initialMainNavigationState,
  mainNavigationActions,
  navigationActions,
  navigationItemsActions,
  profileActions
} from "@vinsolutions/ccrm/store";
import { AnyAction } from "redux";
import { FailureState } from "@vinsolutions/core/errors";
import { useVinconnectFlagEnabled } from "@vinsolutions/core/third-party/launch-darkly";

const logger = createLogger("navigation-iframe");

interface Props {
  cdFrameRef: React.RefObject<CarDashboardFrame>;
  frameUrl: string;
  onLoad?: React.ReactEventHandler<HTMLIFrameElement>;
  visible?: boolean;
}

/**
 * Used to display a CarDashboard Component and scrape navigation
 * @prop {React.RefObject<CarDashboardFrame>} cdFrameRef Reference object to attach to iframe
 * @prop {string} frameUrl The url to load
 * @prop {React.ReactEventHandler<HTMLIFrameElement>?} onLoad Optional onLoad event handler to attach to iframe
 * @prop {boolean} visible Optionally control visibility of the iframe
 */
export function NavigationIframe({
  cdFrameRef,
  frameUrl,
  onLoad,
  visible
}: Props) {
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);
  const { crwDealListEnabled, loadingStatus: crwDealListEnabledLoadingStatus } =
    useSelector(getMainNavigationState, shallowEqual);
  const { dynamicMenu } = useDynamicHeader();
  const { activeTabId } = useSelector(getMainNavigationState);
  const blocklistLdFlag = useVinconnectFlagEnabled(
    "cardashboard.vinconnect.showblocklist-ui"
  );

  async function scrapeMainNavigation() {
    logger.debug("scrapeMainNavigation");

    if (dynamicMenu?.shouldUseDynamicMenu) {
      logger.debug("scrapeMainNavigation shouldUseDynamicMenu");

      return;
    }

    if (cdFrameRef?.current?.contentWindow?.getNavItems) {
      logger.debug("scrapeMainNavigation getNavItems");

      let { navigationItemsEntities, navigationEntities } =
        await deriveMainNavigation(
          cdFrameRef.current.contentWindow.getNavItems
        );
      if (crwDealListEnabled) {
        const newNavItems = deskingDealListNavItems();
        const navigationArrays = addNavigationItems({
          navigationItemsEntities,
          navigationEntities,
          newNavItems
        });
        navigationItemsEntities = navigationArrays.navigationItemsEntities;
        navigationEntities = navigationArrays.navigationEntities;
      }

      if (blocklistLdFlag) {
        const newNavItems = blocklistNavItems();
        const navigationArrays = addNavigationItems({
          navigationItemsEntities,
          navigationEntities,
          newNavItems
        });
        navigationItemsEntities = navigationArrays.navigationItemsEntities;
        navigationEntities = navigationArrays.navigationEntities;
      }

      if (
        !navigationEntities ||
        navigationEntities.length === 0 ||
        !navigationItemsEntities ||
        navigationItemsEntities.length === 0
      ) {
        logger.error("scrapeMainNavigation failed");
        return; // Short circuiting in the case that the scraper fails.
      }

      navigationItemsEntities[0].id = navigationEntities[0].id;

      overrideJavascriptUrls(navigationItemsEntities);
      overrideNavigationUrls(navigationItemsEntities);
      generateDuplicateNavigationItems(
        navigationEntities,
        navigationItemsEntities
      );

      // Add the dynamic menu items
      addDynamicMenuItems(
        dynamicMenu,
        navigationEntities,
        navigationItemsEntities
      );

      batch(() => {
        logger.debug("scrapeMainNavigation batch");

        if (
          navigationEntities.length > 0 &&
          (activeTabId === initialMainNavigationState.activeTabId ||
            !activeTabIsValid(navigationEntities))
        ) {
          logger.debug("scrapeMainNavigation setActiveTab");
          dispatch(
            mainNavigationActions.setActiveTab(navigationEntities[0]?.id)
          );
        }
        dispatch(navigationActions.set(navigationEntities));
        dispatch(navigationItemsActions.set(navigationItemsEntities));
        dispatch(navigationActions.loaded());
      });

      overrideCommunicationsItems();
      insertDomainEmaiLCheckPage(navigationEntities);
      scrapeEmailCount();
    }
  }

  async function scrapeViewingAs() {
    logger.debug("scrapeViewingAs");

    const res = await deriveViewingAs();
    dispatch(profileActions.update({ ...res }));
  }

  async function scrapeVinConnectServerName() {
    logger.debug("scrapeVinConnectServerName");

    if (cdFrameRef?.current?.contentDocument?.title) {
      const serverName = await getVinconnectServerName(
        cdFrameRef.current.contentDocument.title
      );
      dispatch(cardashboardActions.updateCardashboardServerName(serverName));
    }
  }

  async function scrapeDataIsland(cdWindow: CarDashboardWindow | null) {
    logger.debug("scrapeDataIsland");

    // move to API layer?
    try {
      if (cdWindow?._dataIsland?.crmSettings) {
        logger.debug(
          "scrapeDataIsland crmSettings",
          cdWindow._dataIsland.crmSettings
        );
        return cdWindow._dataIsland.crmSettings;
      } else {
        return null;
      }
    } catch (ex) {
      return null;
    }
  }

  async function keepDealerIdInSync() {
    logger.debug("keepDealerIdInSync");

    if (cdFrameRef?.current?.contentWindow) {
      const res = await scrapeDataIsland(cdFrameRef.current.contentWindow);
      // this is safe because we have an explicit prev value compare when dealerid is set
      profileActions.update(res);
    }
  }

  async function keepFrameUrlInSync() {
    logger.debug("keepFrameUrlInSync");

    if (
      cdFrameRef?.current?.src &&
      decodeURI(cdFrameRef.current.src) !== decodeURI(frameUrl)
    ) {
      dispatch(
        cardashboardActions.updateFrameUrl({
          name: "keepFrameUrlInSync",
          frameUrl:
            cdFrameRef.current.contentWindow?.location?.pathname ||
            "/Cardashboard"
        })
      );
    }
  }

  async function keepNewsCountInSync() {
    logger.debug("keepNewsCountInSync");

    if (
      cdFrameRef?.current?.src &&
      cdFrameRef.current.src.includes("News.aspx")
    ) {
      dispatch(fetchNotifications() as unknown as AnyAction);
    }
  }

  function activeTabIsValid(navigationEntities: NavigationEntity[]) {
    logger.debug("activeTabIsValid");

    if (activeTabId === null) {
      logger.debug("activeTabIsValid activeTabId is null");
      return false;
    } else {
      logger.debug("activeTabIsValid activeTabId", activeTabId);
      const idList = navigationEntities.map(
        (entity: NavigationEntity) => entity.id
      );

      return idList.includes(activeTabId);
    }
  }

  function overrideCommunicationsItems() {
    logger.debug("overrideCommunicationsItems");
    const { communicationsItemEntities, communicationsTabNavigationEntity } =
      getCommunicationsNavigationItemEntities();

    if (
      communicationsItemEntities &&
      communicationsItemEntities.length > 0 &&
      communicationsTabNavigationEntity
    ) {
      batch(() => {
        dispatch(navigationActions.upsert(communicationsTabNavigationEntity));
        dispatch(navigationItemsActions.upsertMany(communicationsItemEntities));
      });
    }
  }

  function insertDomainEmaiLCheckPage(navigationEntities: NavigationEntity[]) {
    function findById(
      navigationEntities: NavigationEntity[] | undefined,
      id: string
    ): NavigationEntity | undefined {
      if (!navigationEntities) {
        return undefined;
      }

      for (let i = 0; i < navigationEntities.length; i++) {
        if (navigationEntities[i].id === id) {
          return navigationEntities[i];
        }
      }

      return undefined;
    }

    batch(() => {
      const id =
        "navigation-sub-menu-tab-settings-dealer-settings-email-setup-status-check";

      const tabMenuItem = findById(navigationEntities, "tab-settings");

      if (!tabMenuItem) {
        return;
      }

      const dealerSettingsMenuItem = findById(
        tabMenuItem.items,
        "navigation-sub-menu-tab-settings-dealer-settings"
      );

      if (!dealerSettingsMenuItem) {
        return;
      }

      const menuitem: NavigationEntity = {
        id: "tab-settings",
        items: tabMenuItem.items?.concat([{ id }])
      };

      const menuItemAction: NavigationItemsEntity = {
        type: "GENERIC_URL",
        id, // menuitem id
        label: "Domain status page",
        url: id, // Alias id
        target: "_self"
      };

      dispatch(navigationActions.upsert(menuitem));
      dispatch(navigationItemsActions.upsert(menuItemAction));
    });
  }

  function scrapeEmailCount() {
    logger.debug("scrapeEmailCount");

    if (cdFrameRef?.current?.contentWindow?.monitorForEmailMenuChanges) {
      logger.debug("scrapeEmailCount monitorForEmailMenuChanges");

      // Triggered by running
      // $("#menuEmail_UnmatchedEmailInbox").children(".rmText").first().text('Unmatched Inbox (7)')
      cdFrameRef.current.contentWindow.monitorForEmailMenuChanges(
        (unmatchedInboxNewText: string) => {
          logger.debug(
            "scrapeEmailCount monitorForEmailMenuChanges unmatchedInboxNewText",
            unmatchedInboxNewText
          );
          // this id is hard coded because I couldn't think of a way to generate it
          dispatch(
            navigationItemsActions.upsert({
              id: crmNavigationItemEntityIds.unmatchedEmail,
              label: unmatchedInboxNewText
            } as NavigationItemsEntity)
          );
        }
      );
    }
  }

  useEffect(() => {
    (async function () {
      if (crwDealListEnabledLoadingStatus === "loaded") {
        await scrapeMainNavigation();
      }
    })();
  }, [crwDealListEnabledLoadingStatus]);

  function onIframeLoad(e: React.SyntheticEvent<HTMLIFrameElement, Event>) {
    try {
      logger.debug("loaded");

      dispatch(cardashboardActions.updateCardashboardPageStatus("loaded"));
      try {
        if (cdFrameRef.current === null) {
          logger.error("is null");
          return;
        }

        const cdWindow = cdFrameRef.current.contentWindow;

        if (!cdWindow) {
          logger.error("has no contentWindow");
          return;
        }
        // We expect this to throw under certain scenarios.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        logger.debug("loaded", cdWindow.location.href);

        if (cdWindow.location.href.length < 1) {
          logger.debug("loaded");
          return;
        }
      } catch (ex) {
        // catch when it hasn't loaded
        logger.error(ex);

        window.location.href =
          window.location.origin + "/Cardashboard/logout.aspx";
        return;
      }

      if (
        cdFrameRef?.current?.contentWindow?.location?.pathname
          .toLowerCase()
          .includes("/cardashboard/error.htm")
      ) {
        logger.error("Cardashboard sent user to error page");

        dispatch(cardashboardActions.updateCardashboardPageStatus("error"));
        // when you get an error page, display a message
        setError("Cardashboard failed to load");
        return;
      }

      logger.debug("call dispatch loaded");
      dispatch(cardashboardActions.loaded());

      logger.debug("dispatch loaded called");

      if (onLoad) {
        onLoad(e);
      }

      Promise.all([
        scrapeMainNavigation(),
        scrapeViewingAs(),
        scrapeVinConnectServerName(),
        keepDealerIdInSync(),
        keepFrameUrlInSync(),
        keepNewsCountInSync()
      ]);

      logger.debug("Promise.all called");
    } catch (ex) {
      logger.error("Error loading iframe.", ex);
    }
  }

  if (error) {
    logger.error("error");
    return <FailureState {...{ error }} />;
  }

  function onIframeError() {
    throw new Error("Cardashboard experienced an error");
  }

  return (
    <iframe
      data-testid="cardashboardframe"
      id="cardashboardframe"
      key={frameUrl}
      ref={cdFrameRef}
      src={frameUrl}
      style={{ visibility: visible === false ? "hidden" : "visible" }}
      title="CarDashboard"
      width="100%"
      onError={onIframeError}
      onLoad={e => onIframeLoad(e)}
    />
  );
}
