export type OemLaunchpadFlagNames = "nx.oem-launchpad.smartvincent.enabled";

/* eslint-disable @cspell/spellchecker -- kbbico */
export type VinconnectFlagNames =
  | "cardashboard.oemservices-dmc-to-stargate"
  | "nx.vinconnect.send-event-for-desking"
  | "nx.vinconnect.show-crw-deallist"
  | "nx.vinconnect.show-comms-inbox"
  | "nx.vinconnect.task-list.enable-test-page"
  | "oem.honda.disposition.update.enabled"
  | "nx.vinconnect.walkme"
  | "nx.vinconnect.pendo"
  | "cardashboard.vinconnect.showblocklist-ui"
  | "nx.vinconnect.vehicle-acquisition.add-lead.enable"
  | "nx.vinconnect.ui.pixall.enabled"
  | "nx.vinconnect.disable-newrelic-interaction";

export type TestFlagNames = "devTestFlag";

type AllFlagsNames =
  | TestFlagNames
  | VinconnectFlagNames
  | OemLaunchpadFlagNames;

type AppNames = "test" | "vinconnect" | "oem-launchpad";

type Flags = Array<{ name: AllFlagsNames; apps: Array<AppNames> }>;

const FLAGS: Flags = [
  /** Mock flag only for test page */
  {
    name: "devTestFlag",
    apps: ["test"]
  },
  {
    /** nx.vinconnect.send-event-for-desking
     * Sends an event on desking pages rather than refreshing */
    name: "nx.vinconnect.send-event-for-desking",
    apps: ["vinconnect"]
  },
  {
    name: "nx.vinconnect.show-crw-deallist",
    apps: ["vinconnect"]
  },
  {
    name: "nx.vinconnect.show-comms-inbox",
    apps: ["vinconnect"]
  },
  {
    /** oem.honda.disposition.update.enabled
     * enables dmc manufacturer codes update for acura and Honda */
    name: "oem.honda.disposition.update.enabled",
    apps: ["vinconnect"]
  },
  {
    /** nx.vinconnect.walkme
     * enaables walkme injection script */
    name: "nx.vinconnect.walkme",
    apps: ["vinconnect"]
  },
  {
    /** nx.vinconnect.pendo
     * enaables pendo injection script */
    name: "nx.vinconnect.pendo",
    apps: ["vinconnect"]
  },
  {
    /** cardashboard.vinconnect.showblocklist-ui
     * enables blocklist menu item in admin section */
    name: "cardashboard.vinconnect.showblocklist-ui",
    apps: ["vinconnect"]
  },
  {
    /** nx.vinconnect.add-lead.enable
     * enabled the add-lead page for vehicle acquisition */
    name: "nx.vinconnect.vehicle-acquisition.add-lead.enable",
    apps: ["vinconnect"]
  },
  {
    /** nx.vinconnect.ui.pixall.enabled
     * enables pixall cdl iframe */
    name: "nx.vinconnect.ui.pixall.enabled",
    apps: ["vinconnect"]
  },
  {
    /** nx.oem-launchpad.smartvincent.enabled
     * activates smartvincent button(Oemlaunchpad) in customer dashboard */
    name: "nx.oem-launchpad.smartvincent.enabled",
    apps: ["oem-launchpad"]
  },
  {
    /** nx.vinconnect.disable-newrelic-interaction
     * default disable newrelic interaction flag */
    name: "nx.vinconnect.disable-newrelic-interaction",
    apps: ["vinconnect"]
  }
];

export const getActiveFlags = (appName: AppNames) => {
  return FLAGS.reduce((acc, { name, apps }, i) => {
    if (apps.some(app => app === appName)) {
      return {
        ...acc,
        [name]: true
      };
    } else {
      return acc;
    }
  }, {});
};
