import { AccessToken } from "..";
import { unParseAccessToken } from "./tenant-jwt-utils";
import { OidcUser } from "./types";

export const exampleAccessToken = {
  iss: "https://dev-authentication.vinsolutions.com",
  aud: "https://dev-authentication.vinsolutions.com/resources",
  exp: 1577992239,
  nbf: 1577988639,
  client_id: "CARDB0000008",
  scope: [
    "TrustedAPI",
    "openid",
    "profile",
    "roles",
    "email",
    "vin.users.query",
    "offline_access"
  ],
  sub: "42b0eacb-277b-438f-81aa-a338ddf8d58d",
  auth_time: 1577988639,
  idp: "vinconnect",
  name: "07ff2a12-c0e8-48f8-b6e9-af890449c197",
  display_name: "Mark Dell",
  email: "mark.dell@vinsolutions.com",
  given_name: "Mark",
  family_name: "Dell",
  organization: "VinSolutions",
  useraccesslevel: "1",
  login: "mdell",
  surrogate_id: "29734",
  vssecurityrole: "1",
  isBridgeUser: "True",
  bridge_platform_id: "44b87511-c3a9-453c-9bf6-9ed4905ae5d3",
  amr: ["external"]
} as AccessToken;
export const exampleOidcUser = {
  id_token: "SECUREIDTOKEN",
  session_state: "SECURESSSIONSTATE",
  access_token: unParseAccessToken(exampleAccessToken),
  token_type: "Bearer",
  scope: "openid profile vin.users.query TrustedAPI",
  profile: {
    sid: "800486fa3c7bdfb7d1d4d3fbaa00ca23d70c5f7bd0157a3b8f628b112c7300fb",
    sub: "42b1eacb-277b-438f-81aa-a338ddf8d58d",
    auth_time: 1633636031,
    idp: "vinconnect",
    amr: ["external"],
    name: "07cf2a12-c0e8-48f8-b6e9-bf890449c197",
    display_name: "Mark Dell",
    given_name: "Mark",
    family_name: "Dell",
    organization: "VinSolutions",
    useraccesslevel: "1",
    surrogate_id: "29734"
  },
  expires_at: 1633639633
} as OidcUser;
