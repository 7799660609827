import { Button } from "@interstate/components/Button";
import { Box } from "@interstate/components/Box";
import { CompleteLeadCtasProps } from "../interfaces/complete-lead-ctas-props";
import { useCallback } from "react";
import { TagManager } from "@vinsolutions/core-third-party-gtm";

export function CompleteLeadCtas({
  dealerId,
  jwt,
  leadId,
  onClose
}: CompleteLeadCtasProps) {
  // TODO: Add real validation that the lead is savable
  const callUpdateLead = useCallback(async () => {
    if (leadId > 0 && dealerId > 0 && jwt) {
      // TODO: Update GTM event once requirements are known (or replace with PixAll?)
      TagManager.event({
        event: "userEvent",
        eventElement: "CompleteLead",
        eventAction: "Finalize button clicked",
        eventResult: "Updated lead",
        eventLocation: "CompleteLead"
      });

      const result = true;

      if (!result) {
        // TODO: Show error message
      }

      if (onClose) {
        onClose();
      }
    }
  }, [dealerId, jwt, leadId]);

  return (
    <Box
      columnGap="1.5em"
      display={leadId ? "flex" : "none"}
      justifyContent={"flex-end"}
      paddingTop={"1em"}
    >
      {onClose && (
        <Button
          buttonStyle="tertiary"
          data-testid="button-cancel"
          id="cancel-button"
          size="medium"
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
        >
          Cancel
        </Button>
      )}
      <Button
        buttonStyle="primary"
        data-testid="button-finalize"
        id="finalize-button"
        size="medium"
        onClick={() => callUpdateLead()}
      >
        Finalize Vehicle Acquisition
      </Button>
    </Box>
  );
}

export default CompleteLeadCtas;
