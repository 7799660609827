import { StyleSheetManager } from "styled-components";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";

type PortalStyleProviderProps = {
  cacheKey: string;
  children: React.ReactNode;
  targetContainer?: HTMLElement | ShadowRoot;
};

const cleanEmotionCacheKey = (cacheKey: string) => {
  return cacheKey.toLowerCase().replace(/[^a-z-]/g, "");
};

/**
 * Wraps components to handle styling issues arising from using style manager across frames.
 * @param cacheKey - A unique key to identify the cache provider for the rendered component.
 * @param children - The children to render.
 */
export const PortalStyleProvider = ({
  cacheKey,
  children,
  targetContainer
}: PortalStyleProviderProps) => {
  const key = cleanEmotionCacheKey(cacheKey);
  const emotionCache = createCache({
    key,
    container: targetContainer
  });
  return (
    <CacheProvider value={emotionCache}>
      <StyleSheetManager disableCSSOMInjection={true} target={targetContainer}>
        {children}
      </StyleSheetManager>
    </CacheProvider>
  );
};

export default PortalStyleProvider;
