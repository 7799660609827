import { useEffect } from "react";
import PortalComponentProps, { RegisteredPortal } from "../interfaces/portals";
import { usePortalsContext } from "../state/portals-context";
import { PortalsFinalizationRegistry } from "../state/portals-finalization-registry";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("userPortalCleanup");

const documentContainsChildIFrameUrl = (
  url: string,
  doc: Document | undefined = window.top?.document
): boolean => {
  if (doc === undefined) return false;
  if (doc.location?.href === url) return true;

  const iframes = doc.getElementsByTagName("iframe");
  for (let i = 0; i < iframes.length; i++) {
    try {
      const frameDoc =
        iframes[i].contentDocument || iframes[i].contentWindow?.document;
      if (frameDoc && documentContainsChildIFrameUrl(url, frameDoc)) {
        return true;
      }
    } catch (e) {
      // Catch and ignore cross-origin frame access errors
    }
  }

  return false;
};

/**
 * Hook to handle the cleanup of a portal when its parent element (originElement) is garbage collected.
 *
 * Key Features:
 * - FinalizationRegistry: Uses a Portals FinalizationRegistry to monitor the garbage collection of registeredPortal originElement and originDocument.
 * - Orphaned Registered Portal Cleanup: Automatically calls the deletePortal function when the originElement or originDocument is garbage collected.
 * - Logging: Logs a debug message when the portal is deleted due to garbage collection.
 *
 * @param {RegisteredPortal<PortalComponentProps>} registeredPortal - The registered portal to monitor.
 *
 * @example
 * import { usePortalCleanup } from "./use-portal-cleanup";
 * import { RegisteredPortal } from "../interfaces/portals";
 *
 * const Portal = ({ registeredPortal }: { registeredPortal: RegisteredPortal<PortalComponentProps> }) => {
 *   usePortalCleanup(registeredPortal);
 *
 *   // Component logic here
 * };
 */
export const usePortalCleanup = ({
  originDocument,
  originElement,
  portalId,
  portalKey
}: RegisteredPortal<PortalComponentProps>) => {
  const { deletePortal } = usePortalsContext();

  // Register originDocument and originElement with the FinalizationRegistry
  // This will handle deleting the registered portal if the referenced originElement or originDocument is garbage collected
  PortalsFinalizationRegistry.register(originDocument, {
    portalId,
    portalKey,
    deletePortal
  });
  PortalsFinalizationRegistry.register(originElement, {
    portalId,
    portalKey,
    deletePortal
  });

  // Handle cleanup when the originDocument is no longer in the DOM
  useEffect(() => {
    const checkIframeUrls = () => {
      const targetUrl = originDocument.deref()?.location?.href;
      if (
        !targetUrl ||
        (targetUrl && !documentContainsChildIFrameUrl(targetUrl))
      ) {
        logger.info(
          `Portal ${portalId} (key: ${portalKey}) origin document is no longer in the DOM. Ensuring removal from portal registry.`
        );
        // Remove the portal if the originDocument is no longer in the DOM
        deletePortal(portalId, portalKey);
      }
    };

    const observer = new MutationObserver(() => {
      checkIframeUrls();
    });

    if (originDocument.deref()) {
      // Observes changes to the document where the top-level portals container is rendered
      observer.observe(document, { childList: true });
    }

    const intervalId = setInterval(checkIframeUrls, 3000); // Check every second

    return () => {
      observer.disconnect();
      clearInterval(intervalId);
    };
  }, [deletePortal, originDocument, portalId, portalKey]);
};
