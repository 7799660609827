import { Box } from "@interstate/components/Box";
import { useCallback, useEffect, useState } from "react";
import { LeadInformation } from "@vinsolutions/data-access/lead/crm.lead.bff";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { getProfileState } from "@vinsolutions/ccrm/store";
import { shallowEqual, useSelector } from "react-redux";
import { useNewRelicInteraction } from "@vinsolutions/core-third-party-newrelic";
import { AcquisitionLeadEditProps } from "./interfaces/acquisition-lead-edit-props";
import { useLocation } from "react-router-dom";
import { FeatureEditLead } from "@vinsolutions/feature/edit-lead";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function AcquisitionLeadEdit({
  leadId: stateLeadId,
  showTitle,
  onClose
}: AcquisitionLeadEditProps) {
  const query = useQuery();

  const { endInteraction, setInteractionAttributes } = useNewRelicInteraction(
    "PageEditLead",
    "Loading Edit Lead"
  );

  const [leadId, setLeadId] = useState<number>(stateLeadId || 0);

  const { dealerId: profileDealerId } = useSelector(
    getProfileState,
    shallowEqual
  );
  const oidcUser = useReactOidc().oidcUser;

  if (leadId <= 0) {
    const queryLeadId = +(
      query.get("leadId") ||
      query.get("AutoLeadID") ||
      "0"
    );

    if (queryLeadId > 0) {
      setLeadId(queryLeadId);
    } else {
      throw new Error("leadId parameter is required");
    }
  }

  const [leadInformation, setLeadInformation] = useState<LeadInformation>(
    {} as LeadInformation
  );

  const dealerId = profileDealerId || 0;

  setInteractionAttributes({
    leadId,
    dealerId
  });

  const handlePageLoad = useCallback(() => {
    endInteraction();
  }, [endInteraction]);

  // TODO: Not working, fix it
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.addEventListener("load", handlePageLoad);

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.removeEventListener("load", handlePageLoad);
    };
  }, []);

  return (
    <Box padding={showTitle ? "1em" : "0 1em 1em 1em"}>
      <FeatureEditLead
        dealerId={dealerId}
        jwt={oidcUser.access_token}
        leadId={leadId}
        leadInformation={leadInformation}
        setLeadInformation={setLeadInformation}
        showTitle={showTitle ?? true}
        onClose={onClose}
      />
    </Box>
  );
}
