import { VehicleInformation } from "../models/vehicle-information";

export const getVehicleInformationMock: VehicleInformation = {
  vehicleId: 1,
  vin: "1M2AA18Y62W149042",
  year: "2020",
  make: "Lexus",
  model: "NX 300",
  trim: "F SPORT",
  mileage: "34,455",
  appraisalValue: "14,000",
  doors: "4",
  trans: "8-Speed Automatic w/ DD",
  engine: "3.5L V6, Gasoline",
  bodyStyle: "Sport Utility",
  exteriorColor: "Black",
  interiorColor: "Black",
  interiorType: "Leather",
  notes: "Lorem ipsum dolor sit"
};
