import { ComboBoxOptions } from "@interstate/components/ComboBox";

export interface EntityBase {
  id: number;
  name: string;
}

export function setMappedResults(
  setter: React.Dispatch<React.SetStateAction<ComboBoxOptions>>,
  results: EntityBase[] | null | undefined
) {
  setter(
    results
      ? results.map(item => ({ value: item.id.toString(), label: item.name }))
      : []
  );
}
