import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useReducer
} from "react";
import {
  PortalComponentProps,
  PortalContextActionTypes,
  PortalEventChannels,
  PortalEventMessage,
  PortalFactoryRegistry,
  PortalMessage,
  PortalsContextAction,
  PortalsContextValue,
  RegisteredPortal
} from "../interfaces/portals";
import { createLogger } from "@vinsolutions/logger";
import { usePortalListener } from "../hooks/use-portal-listener";

const logger = createLogger("portals-context");

type PortalState = Record<string, RegisteredPortal<PortalComponentProps>>;

const PortalsContext = createContext<PortalsContextValue | undefined>(
  undefined
);

const registeredPortalsReducer = (
  prevState: PortalState,
  action: PortalsContextAction
) => {
  switch (action.type) {
    case PortalContextActionTypes.createPortals: {
      const registeredPortalFactory =
        action.registerPortalMessage && action.portalFactoryRegistry
          ? action.portalFactoryRegistry[action.registerPortalMessage.portalId]
          : null;
      const registeredPortal = action.registerPortalMessage
        ? registeredPortalFactory?.create(action.registerPortalMessage)
        : null;
      if (registeredPortal) {
        const existingPortal = Object.values(prevState).find(
          portal => portal.portalId === registeredPortal.portalId
        );
        if (existingPortal) {
          logger.warn(
            `Attempt to create a portal id (${
              existingPortal.portalId || "unknown"
            }) that has already been created. Replacing the matching existing portal key: ${
              existingPortal.portalKey
            }.`
          );
          const existingPortalParent = existingPortal.originElement.deref();
          if (existingPortalParent) {
            // remove existing portal content
            existingPortalParent.replaceChildren();
            existingPortalParent.remove();
          }
          const { [existingPortal.portalKey]: _, ...rest } = prevState;
          return {
            ...rest,
            [registeredPortal.portalKey]: registeredPortal
          };
        }
        return {
          ...prevState,
          [registeredPortal.portalKey]: registeredPortal
        };
      } else {
        if (action.registerPortalMessage?.portalProps?.portalEnabledCallback) {
          action.registerPortalMessage.portalProps.portalEnabledCallback(false);
        }
        logger.error(
          `Attempt to create a portal (${
            action.registerPortalMessage?.portalId || "unknown"
          }) that doesn't exist in the portal registry`
        );
      }
      return prevState;
    }
    case PortalContextActionTypes.deletePortals: {
      let removePortalKey = action.targetPortalKey;
      if (!removePortalKey && action.targetPortalId) {
        const existingPortal = Object.values(prevState).find(
          portal => portal.portalId === action.targetPortalId
        );
        removePortalKey = existingPortal?.portalKey;
      }
      if (removePortalKey && prevState[removePortalKey]) {
        const { [removePortalKey]: _, ...rest } = prevState;
        return rest;
      }
      return prevState;
    }
    case PortalContextActionTypes.showPortal:
    case PortalContextActionTypes.hidePortal: {
      let togglePortalKey = action.targetPortalKey;
      if (!togglePortalKey && action.targetPortalId) {
        const existingPortal = Object.values(prevState).find(
          portal => portal.portalId === action.targetPortalId
        );
        togglePortalKey = existingPortal?.portalKey;
      }
      if (togglePortalKey) {
        return {
          ...prevState,
          [togglePortalKey]: {
            ...prevState[togglePortalKey],
            isHidden: action.type !== PortalContextActionTypes.showPortal
          }
        };
      }
      return prevState;
    }
    default:
      return prevState;
  }
};

export interface PortalContextProps {
  portalFactoryRegistry: PortalFactoryRegistry;
  portalEventChannels: PortalEventChannels;
  children: ReactNode;
}

const PortalsContextProvider = ({
  portalFactoryRegistry,
  portalEventChannels,
  children
}: PortalContextProps) => {
  const [registeredPortals, registeredPortalsDispatch] = useReducer(
    registeredPortalsReducer,
    {}
  );

  const deletePortal = useCallback(
    (targetPortalId: string, targetPortalKey?: string) => {
      registeredPortalsDispatch({
        type: PortalContextActionTypes.deletePortals,
        targetPortalId,
        targetPortalKey,
        portalFactoryRegistry
      });
    },
    [portalFactoryRegistry]
  );

  const createPortals = useCallback(
    (eventDetail: PortalEventMessage) => {
      eventDetail.registerPortals.forEach(registerPortal => {
        const originElement =
          registerPortal.targetElement ||
          eventDetail.originDocument?.getElementById(
            registerPortal.targetElementId || ""
          );
        if (originElement !== null && originElement !== undefined) {
          const portalKey = crypto.randomUUID();
          const portalHtmlId = `${originElement.id}-portal`;
          const registerPortalMessage: PortalMessage = {
            originDocument: new WeakRef(eventDetail.originDocument),
            originElement: new WeakRef(originElement),
            portalId: registerPortal.portalId,
            portalKey,
            portalProps: {
              portalId: registerPortal.portalId,
              data: eventDetail.data,
              portalHtmlId,
              portalEnabledCallback: registerPortal.portalEnabledCallback
            }
          };
          registeredPortalsDispatch({
            type: PortalContextActionTypes.createPortals,
            portalFactoryRegistry,
            registerPortalMessage,
            targetPortalId: registerPortal.portalId
          });
        } else {
          logger.error(
            `Failed to find portal target element id ${registerPortal.targetElementId} on the provided frame`
          );
        }
      });
    },
    [portalFactoryRegistry]
  );

  const deletePortals = useCallback(
    (eventDetail: PortalEventMessage) => {
      eventDetail.registerPortals.forEach(registerPortal => {
        deletePortal(registerPortal.portalId);
      });
    },
    [deletePortal]
  );

  const setPortalVisibility = useCallback(
    (targetPortalId: string, isVisible: boolean) => {
      registeredPortalsDispatch({
        type: isVisible
          ? PortalContextActionTypes.showPortal
          : PortalContextActionTypes.hidePortal,
        targetPortalId,
        portalFactoryRegistry
      });
    },
    [portalFactoryRegistry]
  );

  usePortalListener(portalEventChannels.createPortals, createPortals);
  usePortalListener(portalEventChannels.deletePortals, deletePortals);
  return (
    <PortalsContext.Provider
      value={{ registeredPortals, setPortalVisibility, deletePortal }}
    >
      {children}
    </PortalsContext.Provider>
  );
};

export const usePortalsContext = () => {
  const portalsContext = useContext(PortalsContext);
  if (portalsContext === undefined) {
    throw new Error(
      "usePortalsContext must be used within a PortalsContextProvider"
    );
  }
  return portalsContext;
};

export default PortalsContextProvider;
