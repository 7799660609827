import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("portals-finalization-registry");

// Create a FinalizationRegistry to handle cleanup if/when the originDocument or originElement is garbage collected
export const PortalsFinalizationRegistry = new FinalizationRegistry<{
  portalId: string;
  portalKey: string;
  deletePortal: (targetPortalId: string, targetPortalKey?: string) => void;
}>(({ portalId, portalKey, deletePortal }) => {
  logger.info(
    `Portal ${portalId} parent was garbage collected. Ensuring deletion from portal registry.`
  );
  deletePortal(portalId, portalKey);
});
