import { RefObject, useEffect, useRef } from "react";
import {
  PortalContainerClass,
  PortalParentHostClass
} from "../styles/portal-style-classes";
import PortalComponentProps, { RegisteredPortal } from "../interfaces/portals";

/**
 * Hook to create a React Portal target.
 * Automatically handles creating and tearing-down the root elements (no SRR
 * makes this trivial), so there is no need to ensure the parent target already
 * exists.
 * @example
 * const target = usePortal(id, [id]);
 * return createPortal(children, target);
 * @param {RegisteredPortal<PortalComponentProps>} registeredPortal The registered portal to render
 * @param {HTMLElementTagNameMap} portalContainerElementTag Optional element tag type to use for the portal container (default is 'div')
 * @returns {HTMLElement} The DOM node to use as the Portal target.
 */
const usePortalTarget = (
  registeredPortal: RegisteredPortal<PortalComponentProps>,
  portalContainerElementTag?: keyof HTMLElementTagNameMap
): RefObject<WeakRef<HTMLElement> | null> => {
  const targetElemRef = useRef<WeakRef<HTMLDivElement> | null>(null);

  useEffect(() => {
    const portalContainer = targetElemRef.current?.deref();
    const parentElem = registeredPortal.originElement.deref();
    if (!parentElem || !portalContainer) return;

    parentElem.classList.add(PortalParentHostClass);

    if (!registeredPortal.isHidden) {
      parentElem.replaceChildren();
      parentElem.appendChild(portalContainer);
    } else {
      portalContainer.remove();
      targetElemRef.current = null;
    }
  }, [registeredPortal.originElement, registeredPortal.isHidden]);

  /**
   * It's important we evaluate this lazily:
   * - We need first render to contain the DOM element, so it shouldn't happen
   *   in useEffect. We would normally put this in the constructor().
   * - We can't do 'const rootElemRef = useRef(document.createElement('div))',
   *   since this will run every single render (that's a lot).
   * - We want the ref to consistently point to the same DOM element and only
   *   ever run once.
   * @link https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
   */
  const getOrCreateTarget = () => {
    if (!targetElemRef.current?.deref() && !registeredPortal.isHidden) {
      const portalContainer = document.createElement(
        portalContainerElementTag || "div"
      );
      const portalContainerId = `${registeredPortal.portalId}-container`;
      portalContainer.setAttribute("id", portalContainerId);
      portalContainer.classList.add(PortalContainerClass);
      const portalContainerRef = new WeakRef(portalContainer);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (targetElemRef as any).current = portalContainerRef;
    }
    return targetElemRef;
  };
  const portalTarget = getOrCreateTarget();
  return portalTarget;
};

export default usePortalTarget;
