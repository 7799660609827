import { Box } from "@interstate/components/Box";
import { useCallback, useEffect, useState } from "react";
import { TagManager } from "@vinsolutions/core-third-party-gtm";
import { Table, TableData, TableRow } from "@interstate/components/Table";
import styled from "styled-components";
import { LeadListProps } from "../interfaces/lead-list-props";

const StyledTable = styled.div`
  && .ant-table-tbody > tr.highlighted-row > td {
    background-color: #ebf6ff;
  }
`;

export function LeadList({
  customerId,
  dealerId,
  jwt,
  selectedLeadId,
  setSelectedLeadId
}: LeadListProps) {
  const [leadList, setLeadList] = useState<TableData>([]);
  const loadLeadList = useCallback(async () => {
    if (customerId > 0 && dealerId > 0 && jwt) {
      // TODO: Update GTM event once requirements are known (or replace with PixAll?)
      TagManager.event({
        event: "userEvent",
        eventElement: "AddLead",
        eventAction: "Save button clicked",
        eventResult: "Saved lead",
        eventLocation: "AddLead"
      });

      const result: TableData = [
        {
          id: 1,
          key: 0,
          status: "Active",
          buyer: "--",
          created: "08/08/24",
          source: "Billboard",
          vehicle: "2020 Lexus NX 300"
        },
        {
          id: 2,
          key: 1,
          status: "Complete",
          buyer: "--",
          created: "08/08/24",
          source: "Billboard",
          vehicle: "2020 Lexus NX 300"
        },
        {
          id: 3,
          key: 2,
          status: "Lost",
          buyer: "--",
          created: "08/08/24",
          source: "Billboard",
          vehicle: "2020 Lexus NX 300"
        }
      ];

      if (result) {
        setLeadList(result);
      } else {
        // TODO: Show error message
      }
    }
  }, [customerId, dealerId, jwt]);

  useEffect(() => {
    loadLeadList();
  }, [loadLeadList]);

  const onRow = (record: TableRow) => ({
    onClick: () => setSelectedLeadId(record["id"])
  });

  const rowClassName = (record: TableRow) => {
    return record["id"] === selectedLeadId ? "highlighted-row" : "";
  };

  function renderStatusColumn(status: string) {
    const color = (() => {
      switch (status) {
        case "Active":
          return "green";
        case "Complete":
          return "blue";
        case "Lost":
          return "red";
        default:
          return "black";
      }
    })();

    return <Box color={color}>{status}</Box>;
  }

  return (
    <Box display={"flex"} justifyContent={"flex-end"} paddingTop={"1em"}>
      <StyledTable>
        <Table
          columns={[
            {
              className: "column-status",
              dataIndex: "status",
              title: "Status",
              width: "20%",
              render: (status: string) => renderStatusColumn(status)
            },
            {
              className: "column-buyer",
              dataIndex: "buyer",
              title: "Buyer/Co-Buyer",
              width: "22%"
            },
            {
              className: "column-created",
              dataIndex: "created",
              title: "Created",
              width: "15%"
            },
            {
              className: "column-source",
              dataIndex: "source",
              title: "Source"
            },
            {
              className: "column-vehicle",
              dataIndex: "vehicle",
              title: "Vehicle",
              width: "26%"
            }
          ]}
          data={leadList}
          dataDensity="small"
          emptyText="No leads"
          highlightOnHover={true}
          id="lead-list"
          rowClassName={rowClassName}
          onRowCallback={onRow}
        />
      </StyledTable>
    </Box>
  );
}

export default LeadList;
