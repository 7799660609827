import { FeatureEditVehicle } from "@vinsolutions/feature/edit-vehicle";
import { Box } from "@interstate/components/Box";
import { useCallback, useEffect, useState } from "react";
import { VehicleInformation } from "@vinsolutions/data-access/lead/crm.lead.bff";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { getProfileState } from "@vinsolutions/ccrm/store";
import { shallowEqual, useSelector } from "react-redux";
import { useNewRelicInteraction } from "@vinsolutions/core-third-party-newrelic";
import { AcquisitionVehicleEditProps } from "./interfaces/acquisition-vehicle-edit-props";
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function AcquisitionVehicleEdit({
  leadId: stateLeadId,
  showTitle,
  onClose
}: AcquisitionVehicleEditProps) {
  const query = useQuery();

  const { endInteraction, setInteractionAttributes } = useNewRelicInteraction(
    "PageEditVehicle",
    "Loading Edit Vehicle"
  );

  const [leadId, setLeadId] = useState<number>(stateLeadId || 0);

  const [vehicleInformation, setVehicleInformation] =
    useState<VehicleInformation>({} as VehicleInformation);

  const { dealerId: profileDealerId } = useSelector(
    getProfileState,
    shallowEqual
  );
  const oidcUser = useReactOidc().oidcUser;

  if (leadId <= 0) {
    const queryLeadId = +(
      query.get("leadId") ||
      query.get("AutoLeadID") ||
      "0"
    );

    if (queryLeadId > 0) {
      setLeadId(queryLeadId);
    } else {
      throw new Error("leadId parameter is required");
    }
  }

  const dealerId = profileDealerId || 0;

  setInteractionAttributes({
    leadId,
    dealerId
  });

  const handlePageLoad = useCallback(() => {
    endInteraction();
  }, [endInteraction]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.addEventListener("load", handlePageLoad);

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.removeEventListener("load", handlePageLoad);
    };
  }, []);

  return (
    <Box padding={showTitle ? "1.75em" : "0 1.75em 1.75em 1.75em"}>
      {showTitle && <h3>Edit Acquisition Vehicle</h3>}

      <FeatureEditVehicle
        dealerId={dealerId}
        isAdding={false}
        jwt={oidcUser.access_token}
        leadId={leadId}
        setVehicleInformation={setVehicleInformation}
        vehicleInformation={vehicleInformation}
        onClose={onClose}
      />
    </Box>
  );
}
