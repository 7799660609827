import { useCallback, useEffect } from "react";
import { EditVehicleVehicleCtas } from "./components/vehicle-ctas";
import { EditVehicleVehicleInformation } from "./components/vehicle-information";
import { EditVehicleProps } from "./interfaces/edit-vehicle-props";
import { getVehicleInformation } from "@vinsolutions/data-access/lead/crm.lead.bff";
import styled from "styled-components";

/**
 * Hides a third div that throws off styling. This will be fixed in Interstate 1.1.0, so this can potentially be removed after that version
 */
const InterstateInputStyleFix = styled.div`
  &&& div.ids-text-input-container.MuiBox-root,
  div.ids-combo-box-root-container.MuiBox-root {
    div.MuiGrid2-container
      div.MuiGrid2-root.MuiGrid2-direction-xs-row:nth-child(3) {
      display: none;
    }
  }
`;

export function FeatureEditVehicle({
  dealerId,
  isAdding,
  jwt,
  leadId,
  vehicleInformation,
  setVehicleInformation,
  onClose
}: EditVehicleProps) {
  const loadVehicle = useCallback(async () => {
    const result =
      jwt && leadId && leadId > 0
        ? await getVehicleInformation(leadId, jwt, true)
        : null;

    if (result) {
      setVehicleInformation(result);
    }
  }, [leadId, jwt, setVehicleInformation]);

  useEffect(() => {
    loadVehicle();
  }, [loadVehicle, leadId]);

  return (
    <InterstateInputStyleFix>
      <EditVehicleVehicleInformation
        dealerId={dealerId}
        isAdding={isAdding}
        jwt={jwt}
        setVehicleInformation={setVehicleInformation}
        vehicleInformation={vehicleInformation}
      />

      {!isAdding && (
        <EditVehicleVehicleCtas
          jwt={jwt}
          leadId={leadId}
          setVehicleInformation={setVehicleInformation}
          vehicleInformation={vehicleInformation}
          onClose={onClose}
        />
      )}
    </InterstateInputStyleFix>
  );
}

export default FeatureEditVehicle;
