import { Box } from "@interstate/components/Box";
import { Button } from "@interstate/components/Button";

export interface OemWarningModalProps {
  onClose: () => void;
  onContinue?: () => void;
}

export function OemWarningModal({ onClose, onContinue }: OemWarningModalProps) {
  return (
    <Box margin={"1em"}>
      This lead is not currently active. Any communication with this lead will
      not be sent to the OEM.
      <Box
        columnGap="1.5em"
        display="flex"
        justifyContent={"flex-end"}
        paddingTop={"1em"}
      >
        <Button
          buttonStyle="tertiary"
          data-testid="button-cancel"
          id="cancel-button"
          size="medium"
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          buttonStyle="primary"
          data-testid="button-continue"
          id="continue-button"
          size="medium"
          onClick={() => {
            if (onClose) {
              onClose();
            }

            if (onContinue) {
              onContinue();
            }
          }}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
}

export default OemWarningModal;
