import { interactionObject } from "@vinsolutions/core-interfaces-third-party-newrelic";
import { interaction } from "./interaction";
import { useEffect, useMemo, useRef } from "react";
import {
  VinconnectFlagNames,
  useVinconnectFlagEnabled
} from "@vinsolutions/core-third-party-launch-darkly";

const applyAttributesToInteraction = (
  newRelicInteraction: interactionObject | undefined,
  attributes: Record<string, string | number | boolean>
) => {
  if (newRelicInteraction) {
    Object.entries(attributes).forEach(([key, value]) => {
      const attributeValue =
        typeof value === "boolean" ? value.toString() : value;
      newRelicInteraction.setAttribute(key, attributeValue);
    });
  }
};

/** Creates a New Relic browser interaction and returns an object to work with the created interaction */
export const useNewRelicInteraction = (
  interactionName: string,
  actionText: string,
  disableFlag: VinconnectFlagNames = "nx.vinconnect.disable-newrelic-interaction"
) => {
  const isInteractionDisabled = useVinconnectFlagEnabled(disableFlag);
  const interactionRef = useRef<interactionObject | undefined>(undefined);
  useEffect(() => {
    if (!isInteractionDisabled) {
      interactionRef.current = interaction()
        ?.setName(interactionName)
        ?.actionText(actionText)
        ?.save()
        ?.onEnd(() => {
          interactionRef.current = undefined;
        });
    } else {
      interactionRef.current = undefined;
    }
    // Force interaction to end as soon as the component is unmounted or one of the dependencies changes
    return () => {
      interactionRef.current?.end();
    };
  }, [isInteractionDisabled, interactionName, actionText]);
  const wrappedInteraction = useMemo(() => {
    return {
      endInteraction: () => {
        interactionRef.current?.end();
      },
      createInteractionTracer: (name: string, callback: () => void) => {
        return interactionRef.current?.createTracer(name, callback);
      },
      getInteractionContext: (callback: () => void) => {
        return interactionRef.current?.getContext(callback);
      },
      setInteractionAttributes: (
        attributes: Record<string, string | number | boolean>
      ) => {
        applyAttributesToInteraction(interactionRef.current, attributes);
      }
    };
  }, [interactionRef]);

  return wrappedInteraction;
};

export default useNewRelicInteraction;
